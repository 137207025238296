import React from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import colors from '@atom/styles/colors';

import './workTemplateSettings.css';

const SettingsTitle = () => {
  const tooltipContent = (
    <div styleName="settings-tooltip">
      <div styleName="settings-tooltip-row">
        <div>&bull;</div>
        <div styleName="settings-tooltip-content">
          When a section is enabled, the child work template must inherit the
          content in that section as it appears on the parent without permission
          to modify the inherited content.
        </div>
      </div>
      <div styleName="settings-tooltip-row">
        <div>&bull;</div>
        <div styleName="settings-tooltip-content">
          When a section is disabled, the child work template will not inherit
          any content from that section.
        </div>
      </div>
      <div styleName="settings-tooltip-row">
        In both scenarios, the child work template can add additional fields,
        files, or items to any of the sections.
      </div>
    </div>
  );

  return (
    <div styleName="header-container">
      <div styleName="title">Child Work Template Settings</div>
      <Tooltip
        content={tooltipContent}
        iconSize="1.125rem"
        iconColor={colors.neutral.gray}
        position="bottom-left"
      />
    </div>
  );
};

export default SettingsTitle;
