import React, { Dispatch, SetStateAction, useState } from 'react';
import * as R from 'ramda';

import { Button } from '@atom/mui';
import { FieldFilter, WorkOrdersConnectionInput } from '@atom/types/work';
import {
  WorkOrderTemplateFolder,
  WorkTemplate,
} from '@atom/types/workTemplate';

import TaskFiltersSection from './AssetWorkOrdersFilters/TaskFiltersSection';
import WorkFiltersSection from './AssetWorkOrdersFilters/WorkFiltersSection';

import './assetWorkOrdersFilterPanel.css';

export interface FilterForm {
  workDueDateStart?: number;
  workDueDateEnd?: number;
  workDueDateSelectValue?: string;
  workCompletionDateStart?: number;
  workCompletionDateEnd?: number;
  workCompletionDateSelectValue?: string;
  selectStatuses: Set<number>;
  workTemplateFolders: WorkOrderTemplateFolder[];
  hasWorkTemplate?: boolean;
  workTemplates: WorkTemplate[];
  workTemplateFields: Record<string, FieldFilter>;
  taskDueDateStart?: number;
  taskDueDateEnd?: number;
  taskDueDateSelectValue?: string;
  taskStartDateStart?: number;
  taskStartDateEnd?: number;
  taskStartDateSelectValue?: string;
  taskCompletionDateStart?: number;
  taskCompletionDateEnd?: number;
  taskCompletionDateSelectValue?: string;
  taskDurationStart?: number;
  taskDurationEnd?: number;
}

const FILTER_DEFAULTS: FilterForm = {
  workDueDateStart: null,
  workDueDateEnd: null,
  workDueDateSelectValue: null,
  workCompletionDateStart: null,
  workCompletionDateEnd: null,
  workCompletionDateSelectValue: null,
  selectStatuses: new Set([]),
  workTemplateFolders: [],
  hasWorkTemplate: null,
  workTemplates: [],
  workTemplateFields: {},
  taskDueDateStart: null,
  taskDueDateEnd: null,
  taskDueDateSelectValue: null,
  taskStartDateStart: null,
  taskStartDateEnd: null,
  taskStartDateSelectValue: null,
  taskCompletionDateStart: null,
  taskCompletionDateEnd: null,
  taskCompletionDateSelectValue: null,
  taskDurationStart: null,
  taskDurationEnd: null,
};

interface Props {
  schemaId: string;
  loadingWorkOrders: boolean;
  isResetFilterButtonDisabled: boolean;
  setActiveFilters: Dispatch<SetStateAction<WorkOrdersConnectionInput>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const AssetWorkOrdersFilterPanel = ({
  schemaId,
  loadingWorkOrders,
  isResetFilterButtonDisabled,
  setActiveFilters,
  setCurrentPage,
}: Props) => {
  const [filterFormData, setFilterFormData] = useState<FilterForm>({
    ...FILTER_DEFAULTS,
  });

  const {
    workDueDateStart,
    workDueDateEnd,
    workDueDateSelectValue,
    workCompletionDateStart,
    workCompletionDateEnd,
    workCompletionDateSelectValue,
    selectStatuses,
    workTemplateFolders,
    hasWorkTemplate,
    workTemplates,
    workTemplateFields,
    taskDueDateStart,
    taskDueDateEnd,
    taskDueDateSelectValue,
    taskStartDateStart,
    taskStartDateEnd,
    taskStartDateSelectValue,
    taskCompletionDateStart,
    taskCompletionDateEnd,
    taskCompletionDateSelectValue,
    taskDurationStart,
    taskDurationEnd,
  } = filterFormData;

  const updateFilterFormData = (
    filterField: keyof FilterForm,
    updatedValue: any,
  ) => {
    setFilterFormData(prevData => ({
      ...prevData,
      [filterField]: updatedValue,
    }));
  };

  const sanitizeAndApplyFilters = () => {
    const workFilters = {
      ...(workDueDateStart && { dueDateStart: workDueDateStart }),
      ...(workDueDateEnd && { dueDateEnd: workDueDateEnd }),
      ...(workCompletionDateStart && {
        completionDateStart: workCompletionDateStart,
      }),
      ...(workCompletionDateEnd && {
        completionDateEnd: workCompletionDateEnd,
      }),
      ...(!R.isEmpty([...selectStatuses]) && {
        statusIds: [...selectStatuses],
      }),
      ...(!R.isEmpty(workTemplateFolders) && {
        workTemplateFolderIds: [
          ...workTemplateFolders.map(folder => folder.id),
        ],
      }),
      ...(!R.isNil(hasWorkTemplate) && { hasWorkTemplate }),
      ...(!R.isEmpty(workTemplates) && {
        workTemplateIds: [...workTemplates.map(template => template.id)],
      }),
      ...(!R.isEmpty(workTemplateFields) && {
        fields: Object.values(workTemplateFields),
      }),
    };

    const taskFilters = {
      ...(taskDueDateStart && { dueDateStart: taskDueDateStart }),
      ...(taskDueDateEnd && { dueDateEnd: taskDueDateEnd }),
      ...(taskStartDateStart && { startDateStart: taskStartDateStart }),
      ...(taskStartDateEnd && { startDateEnd: taskStartDateEnd }),
      ...(taskCompletionDateStart && {
        completionDateStart: taskCompletionDateStart,
      }),
      ...(taskCompletionDateEnd && {
        completionDateEnd: taskCompletionDateEnd,
      }),
      ...(taskDurationStart && { durationStart: taskDurationStart }),
      ...(taskDurationEnd && { durationEnd: taskDurationEnd }),
    };

    setActiveFilters({
      ...(!R.isEmpty(workFilters) && { ...workFilters }),
      ...(!R.isEmpty(taskFilters) && { tasks: { ...taskFilters } }),
    });
    setCurrentPage(1);
  };

  const onResetFilters = () => {
    setFilterFormData({ ...FILTER_DEFAULTS });
    setActiveFilters({});
    setCurrentPage(1);
  };

  const areFiltersDisabled: boolean = loadingWorkOrders;

  return (
    <>
      <div styleName="header filter-section">
        <div styleName="header-title">Filters</div>
        <div styleName="header-button-container">
          <Button
            onClick={onResetFilters}
            disabled={isResetFilterButtonDisabled || areFiltersDisabled}
          >
            Reset
          </Button>
          <Button
            color="primary"
            onClick={sanitizeAndApplyFilters}
            disabled={areFiltersDisabled}
          >
            Apply
          </Button>
        </div>
      </div>
      <WorkFiltersSection
        schemaId={schemaId}
        areFiltersDisabled={areFiltersDisabled}
        workDueDateSelectValue={workDueDateSelectValue}
        workDueDateStart={workDueDateStart}
        workDueDateEnd={workDueDateEnd}
        workCompletionDateSelectValue={workCompletionDateSelectValue}
        workCompletionDateStart={workCompletionDateStart}
        workCompletionDateEnd={workCompletionDateEnd}
        selectStatuses={selectStatuses}
        workTemplateFolders={workTemplateFolders}
        hasWorkTemplate={hasWorkTemplate}
        workTemplates={workTemplates}
        workTemplateFields={workTemplateFields}
        updateFilterFormData={updateFilterFormData}
      />
      <TaskFiltersSection
        areFiltersDisabled={areFiltersDisabled}
        taskDueDateSelectValue={taskDueDateSelectValue}
        taskDueDateStart={taskDueDateStart}
        taskDueDateEnd={taskDueDateEnd}
        taskStartDateSelectValue={taskStartDateSelectValue}
        taskStartDateStart={taskStartDateStart}
        taskStartDateEnd={taskStartDateEnd}
        taskCompletionDateSelectValue={taskCompletionDateSelectValue}
        taskCompletionDateStart={taskCompletionDateStart}
        taskCompletionDateEnd={taskCompletionDateEnd}
        taskDurationStart={taskDurationStart}
        taskDurationEnd={taskDurationEnd}
        updateFilterFormData={updateFilterFormData}
      />
    </>
  );
};

export default AssetWorkOrdersFilterPanel;
