import React, { useState } from 'react';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { MediaType } from '@atom/types/media';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

const styles = {
  container: {
    boxSizing: 'border-box',
    height: '3.125rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    borderBottom: `1px solid ${colors.neutral.ash}`,
  },
  buttonContainer: {
    display: 'flex',
  },
};

interface Props {
  loading: boolean;
  type: MediaType;
  totalCount: number;
  selectedItems: Set<string>;
  isEditDisabled: boolean;
  isInherited?: boolean;
  handleUpload: (event: any) => void;
  handleDownloadMultipleFiles: () => void;
  handleDeleteMultipleMedia: () => void;
}

const MediaTableHeader = ({
  loading,
  type,
  totalCount,
  selectedItems,
  isEditDisabled,
  isInherited = false,
  handleUpload,
  handleDownloadMultipleFiles,
  handleDeleteMultipleMedia,
}: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const deleteMedia = async () => {
    await handleDeleteMultipleMedia();
    setDeleteModalOpen(false);
  };

  const typeLabel = totalCount === 1 ? type : `${type}s`;
  const label =
    selectedItems.size === 0
      ? `${totalCount} ${typeLabel}`
      : `${selectedItems.size} of ${totalCount} ${type}s selected`;
  const labelStyle = {
    color: selectedItems.size === 0 ? colors.neutral.dark : colors.brand.blue,
  };

  const showUploadButton = selectedItems.size === 0 && !isEditDisabled;
  const showDownloadButton = selectedItems.size > 0;
  const showDeleteButton =
    selectedItems.size > 0 && !isEditDisabled && !isInherited;

  return (
    <>
      <div style={styles.container}>
        <div style={labelStyle}>{label}</div>
        <div style={styles.buttonContainer}>
          {loading && <Progress size={16} thickness={5} />}
          {showUploadButton && (
            <IconButton component="label">
              <Icon>file_upload</Icon>
              <input hidden type="file" multiple onChange={handleUpload} />
            </IconButton>
          )}
          {showDownloadButton && (
            <IconButton onClick={handleDownloadMultipleFiles}>
              <Icon>file_download</Icon>
            </IconButton>
          )}
          {showDeleteButton && (
            <IconButton onClick={() => setDeleteModalOpen(true)}>
              <Icon>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        onConfirm={deleteMedia}
        onCancel={() => setDeleteModalOpen(false)}
        title={`Delete ${textDisplayUtilities.capitalize(type)}`}
        content={`Are you sure you want to delete these ${type}s?`}
      />
    </>
  );
};

export default MediaTableHeader;
