import React from 'react';

import { Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './taskItemTile.css';

interface Props {
  content: string;
  contentColor?: string;
  tooltipContent?: string;
  onTileClick?: (event: any) => void;
  icon: React.ReactNode;
  actionButton?: React.ReactNode;
  // maxWidth can be used to set a max-width for the tile.
  // Content that exceeds the width will be shortened with ellipsis
  // And fully rendered in a hover tooltip
  maxWidth?: string;
  containerStyles?: any;
}

const TaskItemTile = ({
  content,
  contentColor = colors.neutral.dim,
  tooltipContent,
  onTileClick = () => {},
  icon,
  actionButton = <div />,
  maxWidth,
  containerStyles = {},
}: Props) => {
  const containerStyle = maxWidth ? { ...containerStyles, maxWidth } : {};
  const contentStyle = { color: contentColor };

  return (
    <div style={containerStyle} styleName="tile-container">
      <div onClick={onTileClick}>{icon}</div>
      <Tooltip
        disableHoverListener={isNilOrEmpty(tooltipContent)}
        title={tooltipContent || ''}
      >
        <div style={contentStyle} styleName="content" onClick={onTileClick}>
          {content}
        </div>
      </Tooltip>
      {actionButton}
    </div>
  );
};

export default TaskItemTile;
