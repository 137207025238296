import React, { useContext, useMemo } from 'react';
import * as R from 'ramda';

import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import { Icon, ListTable, Menu } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserDetail } from '@atom/types/user';
import { WorkOrder, WorkOrderUserGroup } from '@atom/types/work';
import {
  WorkOrderColumn,
  WorkOrderColumnProperty,
} from '@atom/types/workColumns';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import {
  setDisplayDate,
  setDurationDisplay,
} from '@atom/utilities/timeUtilities';
import {
  getUserFullName,
  rollUpTaskUsers,
} from '@atom/utilities/userUtilities';
import workOrderPriorityUtilities from '@atom/utilities/workOrderPriorityUtilities';

import WorkOrderStatusPill from '../common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkPriorityIcon from '../common/WorkPriorityIcon';

import CustomTaskFieldCell from './CustomTaskFieldCell';
import CustomWorkFieldCell from './CustomWorkFieldCell';
import WorkOrdersContext from './WorkOrdersContext';

import './workOrders.css';

const { TableRow, TableCell } = ListTable;

const { MenuItem } = Menu;

interface Props {
  workOrder: WorkOrder;
  loadingDuplicate: boolean;
  onDuplicate: () => void;
  onDelete: () => void;
}

const styles = {
  link: {
    fontWeight: 500,
  },
};

const WorkOrderTableRow = ({
  workOrder,
  onDuplicate,
  loadingDuplicate,
  onDelete,
}: Props) => {
  const { input, setInput, workOrderColumns } = useContext(WorkOrdersContext);

  const isDeleteEnabled = isCurrentTenant([
    Tenant.DEV_HENNEPIN,
    Tenant.QA_HENNEPIN,
    Tenant.UAT_HENNEPIN,
    Tenant.HENNEPIN,
  ])
    ? hasRolePermissions(ROLE_SETS.MANAGER)
    : hasRolePermissions(ROLE_SETS.ORG_ADMIN);
  const isDuplicateEnabled = hasRolePermissions(ROLE_SETS.INSPECTOR);
  const showActionMenu = isDeleteEnabled || isDuplicateEnabled;

  const userGroups = useMemo(() => {
    return workOrder.tasks.reduce(
      (acc: WorkOrderUserGroup[], task) => [...acc, ...task.userGroups],
      [],
    );
  }, [workOrder]);

  const rowBackgroundColor =
    input.workOrderId === workOrder.id
      ? colors.neutral.typhoon
      : colors.neutral.white;

  const getTableCell = (column: WorkOrderColumn) => {
    const cellContent = {
      [WorkOrderColumnProperty.WORK_FIELD]: (
        <CustomWorkFieldCell workOrder={workOrder} column={column} />
      ),
      [WorkOrderColumnProperty.TASK_FIELD]: (
        <CustomTaskFieldCell workOrder={workOrder} column={column} />
      ),
      [WorkOrderColumnProperty.PRIORITY]: (
        <>
          <WorkPriorityIcon priorityId={workOrder.priorityId} />
          <span>
            {workOrderPriorityUtilities.getWorKOrderListPriorityLabel(
              workOrder?.priorityId,
            )}
          </span>
        </>
      ),
      [WorkOrderColumnProperty.STATUS]: (
        <WorkOrderStatusPill
          className="table"
          statusId={workOrder?.statusId}
          style={{ margin: 0 }}
        />
      ),
      [WorkOrderColumnProperty.INVENTORY_NAME]: workOrder?.inventoryAssetName,
      [WorkOrderColumnProperty.DUE_DATE]: setDisplayDate(
        column.taskId
          ? workOrder.tasks.find(task => task.id === column.taskId)?.dueDate
          : workOrder?.dueDate,
      ),
      [WorkOrderColumnProperty.ASSIGNED_TO]: (
        <WorkUsers
          users={rollUpTaskUsers(workOrder?.tasks as any)}
          userGroups={userGroups}
        />
      ),
      [WorkOrderColumnProperty.COMPLETED_DATE]: setDisplayDate(
        column.taskId
          ? workOrder.tasks.find(task => task.id === column.taskId)
              ?.completedDate
          : workOrder?.completionDate,
      ),
      [WorkOrderColumnProperty.COMPLETED_BY]: workOrder.completedBy
        ? getUserFullName(workOrder?.completedBy as UserDetail)
        : '-',
      [WorkOrderColumnProperty.NUM_TASKS]: R.length(workOrder?.tasks),
      [WorkOrderColumnProperty.NUM_FORMS]: workOrder.tasks.reduce(
        (acc, task) => {
          return acc + R.length(task.formInstanceIds);
        },
        0,
      ),
      [WorkOrderColumnProperty.WORK_TYPE]: workOrder?.workTemplate?.name,
      [WorkOrderColumnProperty.CREATED_DATE]: setDisplayDate(
        workOrder?.createdDate,
      ),
      [WorkOrderColumnProperty.CREATED_BY]: workOrder.createdBy
        ? getUserFullName(workOrder?.createdBy as UserDetail)
        : '-',
      [WorkOrderColumnProperty.START_DATE]: setDisplayDate(
        column.taskId
          ? workOrder.tasks.find(task => task.id === column.taskId)?.startTime
          : workOrder?.startTime,
      ),
      [WorkOrderColumnProperty.DURATION]: setDurationDisplay(
        column.taskId
          ? workOrder.tasks.find(task => task.id === column.taskId)?.duration
          : workOrder?.duration,
      ),
    };

    return (
      (
        <TableCell key={column?.label}>
          {cellContent[column.property]}
        </TableCell>
      ) || <TableCell />
    );
  };

  return (
    <TableRow key={workOrder.id} backgroundColor={rowBackgroundColor}>
      <TableCell style={styles.link}>
        <div
          styleName="work-order-name"
          onClick={() => setInput({ ...input, workOrderId: workOrder.id })}
        >
          {workOrder.name}
        </div>
      </TableCell>
      {workOrderColumns.map(column => getTableCell(column))}
      <TableCell>
        {showActionMenu && (
          <Menu>
            {isDuplicateEnabled && (
              <MenuItem
                startAdornment={<Icon>content_copy</Icon>}
                onClick={onDuplicate}
                disabled={loadingDuplicate}
              >
                Duplicate
              </MenuItem>
            )}
            {isDeleteEnabled && (
              <MenuItem startAdornment={<Icon>delete</Icon>} onClick={onDelete}>
                Delete
              </MenuItem>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(WorkOrderTableRow);
