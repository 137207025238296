import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import LocationTile from './LocationTile';

import './locationSection.css';

interface Props {
  setOpenTaskLocation: (openTaskLocation: boolean) => void;
  setOpenTaskMap: (openTaskMap: boolean) => void;
  previewView: boolean;
}

const LocationSection = ({
  setOpenTaskLocation,
  setOpenTaskMap,
  previewView,
}: Props) => {
  const {
    workOrderDetail,
    task,
    locations,
    refetch,
    workValidations,
    dataCySuffix,
  } = useContext(LocationsAndAssetsContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [openLocationSettings, setOpenLocationSettings] = useState<boolean>(
    false,
  );
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    setOpenTaskLocation(true);
  };

  const handleAddClick =
    task.isCompleted && task?.requireAtLeastOneLocation
      ? () => setOpenFieldsConfirm(true)
      : () => setOpenTaskLocation(true);

  const icon = expanded ? 'expand_less' : 'expand_more';
  const isAddDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const showAddButton = expanded && !isAddDisabled;
  const showViewOnMapButton = expanded && !R.isEmpty(task?.locations);

  const isSectionEmpty = R.isEmpty(locations);
  const totalCount = R.length(locations);

  const locationError = R.pathOr(
    false,
    ['taskValidations', task.id, 'missingRequiredLocation'],
    workValidations,
  );

  const requiredStyles = {
    color: locationError ? colors.brand.red : colors.neutral.gray,
  };
  const helpIconStyles = {
    fontSize: '1rem',
    color: locationError ? colors.brand.red : colors.neutral.gray,
  };
  const containerStyle = {
    justifyContent: previewView ? 'space-between' : 'flex-start',
  };
  const listStyle = {
    flexFlow: previewView ? 'column nowrap' : 'row wrap',
    marginRight: previewView ? '0.75rem' : 'none',
  };

  return (
    <>
      <div style={containerStyle} styleName="section-container">
        <div styleName="title-container">
          <Icon>place</Icon>
          <div styleName="section-title">Locations</div>
          {task?.requireAtLeastOneLocation && (
            <div style={requiredStyles} styleName="required-content">
              <span>{`(Required`}</span>
              <div
                styleName="help-icon-container"
                onClick={() => setOpenLocationSettings(true)}
              >
                <Icon style={helpIconStyles}>help</Icon>
              </div>
              <span>{`)`}</span>
            </div>
          )}
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="buttons-container">
          {showViewOnMapButton && (
            <IconButton
              edge="end"
              tooltip="View On Map"
              onClick={() => setOpenTaskMap(true)}
              data-cy={`locationSectionMap_${dataCySuffix}`}
            >
              <Icon>map</Icon>
            </IconButton>
          )}
          {showAddButton && (
            <IconButton
              edge="end"
              tooltip="Add Location"
              onClick={handleAddClick}
              disabled={isAddDisabled}
              data-cy={`locationSectionAdd_${dataCySuffix}`}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div style={listStyle} styleName="tile-list">
          {isSectionEmpty ? (
            <div styleName="empty-section-text">Add Locations</div>
          ) : (
            <>
              {locations.map(location => (
                <LocationTile key={location?.id} location={location} />
              ))}
            </>
          )}
        </div>
      )}
      <Modal
        open={openLocationSettings}
        onCancel={() => setOpenLocationSettings(false)}
        title="Location Settings"
        footer={
          <div styleName="footer">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenLocationSettings(false)}
            >
              Ok
            </Button>
          </div>
        }
      >
        <strong>At least 1 location</strong> is required for the task to be
        completed or the work to be completed or closed.
      </Modal>
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default LocationSection;
