import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_BUDGET_TEMPLATES } from '@atom/graph/budget';
import { Button, Icon, IconButton, Select } from '@atom/mui';
import { BudgetTemplate, BudgetTemplatesConnection } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import PolicyModalContext from '../PolicyModalContext';

import '../policyModals.css';

const { MenuItem } = Select;

const BudgetingUnitTemplateSelection = () => {
  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const budgetTemplates: BudgetTemplate[] = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const { state, updateState } = useContext(PolicyModalContext);
  const {
    grants,
    grantType,
    budgetUnitTemplateId,
    loadingExistingPolicies,
  } = state;

  // Temporarily selecting the first template returned
  // - This is because there is only one template in the data for the
  //   initial clients. To support multiple templates here will require
  //   adding the budgetTemplateId to the policy data.
  // TODO: https://atomai.atlassian.net/browse/AM-15574
  useEffect(() => {
    const firstTemplateId = budgetTemplates[0]?.id || '';
    updateState({ budgetUnitTemplateId: firstTemplateId });
  }, [budgetTemplates]);

  const handleChange = event => {
    updateState({ budgetUnitTemplateId: event.target.value });
  };

  return (
    <>
      <div styleName="input-container">
        <Select
          loading={loadingExistingPolicies}
          label="Budget Template"
          value={budgetUnitTemplateId || ''}
          onChange={handleChange}
        >
          {budgetTemplates.map(budgetTemplate => (
            <MenuItem key={budgetTemplate.id} value={budgetTemplate.id}>
              {budgetTemplate.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div styleName="input-container">
        <div styleName="label">BudgetUnit(s)</div>

        {grants.length ? (
          <div styleName="tile">
            {grants.map(({ name }) => name).join(', ')}
            <div styleName="tile">
              <IconButton
                size="small"
                onClick={() => updateState({ view: grantType })}
                disabled={loadingExistingPolicies}
              >
                <Icon>edit</Icon>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => updateState({ grants: [] })}
                disabled={loadingExistingPolicies}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
          </div>
        ) : (
          <Button
            size="small"
            color="primary"
            style={{ minWidth: 0, padding: 0 }}
            onClick={() => updateState({ view: grantType })}
            disabled={
              loadingExistingPolicies ||
              loadingTemplates ||
              isNilOrEmpty(budgetUnitTemplateId)
            }
          >
            Select
          </Button>
        )}
      </div>
    </>
  );
};

export default BudgetingUnitTemplateSelection;
