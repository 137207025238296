import React from 'react';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import { Icon, Select } from '@atom/mui';
import { EventType } from '@atom/types/event';
import {
  WorkOrderTemplateFolder,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import {
  DateRangePresetOption,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';
import { workOrderStatusTypes } from '@atom/utilities/workOrderStatusUtilities';

import { FilterForm } from '../AssetWorkOrdersFilterPanel';

import TemplateFilter from './TemplateFilter';
import TemplateFolderFilter from './TemplateFolderFilter';
import WorkTemplateFieldsFilter from './WorkTemplateFieldsFilter';

import './../assetWorkOrdersFilterPanel.css';

const { MenuItem: SelectMenuItem } = Select;

interface Props {
  schemaId: string;
  areFiltersDisabled: boolean;
  workDueDateSelectValue?: FilterForm['workDueDateSelectValue'];
  workDueDateStart?: FilterForm['workDueDateStart'];
  workDueDateEnd?: FilterForm['workDueDateEnd'];
  workCompletionDateSelectValue?: FilterForm['workCompletionDateSelectValue'];
  workCompletionDateStart?: FilterForm['workCompletionDateStart'];
  workCompletionDateEnd?: FilterForm['workCompletionDateEnd'];
  selectStatuses?: FilterForm['selectStatuses'];
  workTemplateFolders?: FilterForm['workTemplateFolders'];
  hasWorkTemplate?: FilterForm['hasWorkTemplate'];
  workTemplates?: FilterForm['workTemplates'];
  workTemplateFields?: FilterForm['workTemplateFields'];
  updateFilterFormData: (field: keyof FilterForm, updatedVal: any) => void;
}

const WorkFiltersSection = ({
  schemaId,
  areFiltersDisabled,
  workDueDateSelectValue,
  workDueDateStart,
  workDueDateEnd,
  workCompletionDateSelectValue,
  workCompletionDateStart,
  workCompletionDateEnd,
  selectStatuses,
  workTemplateFolders,
  hasWorkTemplate,
  workTemplates,
  workTemplateFields,
  updateFilterFormData,
}: Props) => {
  const handleStatusSelect = (event: EventType) => {
    const {
      target: { value },
    } = event;
    updateFilterFormData('selectStatuses', value);
  };

  const handleInputDateRangeChange = (
    dateValue: Date,
    terminator: DateRangeTerminator,
    formDataKey: keyof FilterForm,
  ) => {
    const dateMillis = dateValue
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;
    updateFilterFormData(formDataKey, dateMillis);
  };

  const handleInputDateRangeSelectChange = (
    formDataKey: keyof FilterForm,
    option: DateRangePresetOption,
  ) => {
    updateFilterFormData(formDataKey, option.value);
  };

  return (
    <div styleName="filter-section">
      <div styleName="filter-section-header">
        <Icon>work</Icon>
        Work
      </div>
      <div styleName="filter-container">
        <InputDateRange
          label="Due Date"
          disabled={areFiltersDisabled}
          presetSelected={workDueDateSelectValue}
          handleSelectDatePreset={(option: DateRangePresetOption) =>
            handleInputDateRangeSelectChange('workDueDateSelectValue', option)
          }
          startDateValue={workDueDateStart ? new Date(workDueDateStart) : null}
          handleStartChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.START,
              'workDueDateStart',
            )
          }
          endDateValue={workDueDateEnd ? new Date(workDueDateEnd) : null}
          handleEndChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.END,
              'workDueDateEnd',
            )
          }
        />
      </div>
      <div styleName="filter-container">
        <InputDateRange
          label="Completed Date"
          disabled={areFiltersDisabled}
          presetSelected={workCompletionDateSelectValue}
          handleSelectDatePreset={(option: DateRangePresetOption) =>
            handleInputDateRangeSelectChange(
              'workCompletionDateSelectValue',
              option,
            )
          }
          startDateValue={
            workCompletionDateStart ? new Date(workCompletionDateStart) : null
          }
          handleStartChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.START,
              'workCompletionDateStart',
            )
          }
          endDateValue={
            workCompletionDateEnd ? new Date(workCompletionDateEnd) : null
          }
          handleEndChange={(dateValue: Date) =>
            handleInputDateRangeChange(
              dateValue,
              DateRangeTerminator.END,
              'workCompletionDateEnd',
            )
          }
        />
      </div>
      <div styleName="filter-container">
        <div styleName="filter-label">Work Status</div>
        <Select
          multiple
          displayEmpty
          value={[...selectStatuses]}
          onChange={event => handleStatusSelect(event)}
          disabled={areFiltersDisabled}
        >
          {workOrderStatusTypes.map(status => (
            <SelectMenuItem key={+status.value} value={+status.value}>
              {status.label}
            </SelectMenuItem>
          ))}
        </Select>
      </div>
      <TemplateFolderFilter
        schemaId={schemaId}
        areFiltersDisabled={areFiltersDisabled}
        workTemplateFolderFilter={workTemplateFolders}
        updateWorkTemplateFolderFilter={(
          templateFolders: WorkOrderTemplateFolder[],
        ) => updateFilterFormData('workTemplateFolders', templateFolders)}
      />
      <TemplateFilter
        schemaId={schemaId}
        areFiltersDisabled={areFiltersDisabled}
        hasWorkTemplateFilter={hasWorkTemplate}
        updateHasWorkTemplateFilter={(hasWorkTemplateUpdate: boolean) =>
          updateFilterFormData('hasWorkTemplate', hasWorkTemplateUpdate)
        }
        workTemplateFilter={workTemplates}
        updateWorkTemplateFilter={(workTemplatesUpdate: WorkTemplate[]) =>
          updateFilterFormData('workTemplates', workTemplatesUpdate)
        }
      />
      {R.length(workTemplates) === 1 && (
        <WorkTemplateFieldsFilter
          areFiltersDisabled={areFiltersDisabled}
          workTemplate={workTemplates[0]}
          fieldFilters={workTemplateFields}
          updateFieldFilters={fieldFiltersUpdate =>
            updateFilterFormData('workTemplateFields', fieldFiltersUpdate)
          }
        />
      )}
    </div>
  );
};

export default WorkFiltersSection;
