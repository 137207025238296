import * as React from 'react';
import { IconButton } from 'material-ui';
import CheckBoxChecked from 'material-ui/svg-icons/toggle/check-box';
import CheckBoxOutline from 'material-ui/svg-icons/toggle/check-box-outline-blank';

import colors from '@atom/styles/colors';

interface Props {
  isChecked?: boolean;
  toggleCheck: () => any;
  disabled?: boolean;
  iconStyle?: any;
}

const Checkbox = ({ isChecked, toggleCheck, disabled, iconStyle }: Props) => {
  return (
    <IconButton
      iconStyle={{
        color: isChecked ? colors.brand.blue : colors.neutral.gray,
        ...iconStyle,
      }}
      onClick={toggleCheck}
      disabled={disabled}
    >
      {isChecked ? <CheckBoxChecked /> : <CheckBoxOutline />}
    </IconButton>
  );
};

export default Checkbox;
