import * as React from 'react';
import * as R from 'ramda';

import FontIconButton from '@atom/components/common/FontIconButton';
import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import { buildAssetFieldName } from '@atom/selectors/formInstanceSelectors';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { FormInstanceAssetItem } from '@atom/types/form';
import {
  HydratedAttributeGroupsItem,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import FileUploadButton from '../FileUploadButton';
import FileUploadTile from '../FileUploadTile';

import InstanceAttributeGroup from './InstanceAttributeGroup';

import '../formInstance.css';

const styles = {
  icon: {
    padding: '0px',
    height: '35px',
    width: '35px',
  },
};

interface PassedProps {
  asset: FormInstanceAssetItem;
  rootSchema: InventorySchemaItem;
  isEditable: boolean;
  media: MediaItem[];
  fieldId: string;
  preview: boolean;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
  ) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isUploadDisabled: boolean;
  savingFormInstance: boolean;
}

type Props = PassedProps;

interface State {
  open: boolean;
}

class InstanceAssetField extends React.Component<Props, State> {
  state = {
    open: true,
  };

  toggleCollapse = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  assetFileUpload = (event: EventType) => {
    const { uploadFiles, asset } = this.props;

    const files = event.target.files;
    const subjectType = 'formInstanceAsset';

    uploadFiles(files, asset.id, subjectType);
  };

  render() {
    const {
      asset,
      rootSchema,
      progressiveUpdateFormInstanceAttribute,
      isEditable,
      media,
      removeFile,
      fieldId,
      preview,
      renameMedia,
      isUploadDisabled,
      savingFormInstance,
    } = this.props;
    const { open } = this.state;

    const leftIcon = schemaUtilities.getSchemaIconFromSchemaOrAsset(rootSchema);
    const collapseIcon = open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    const name = buildAssetFieldName(asset.name, asset.ancestors);
    const attributeGroups = R.pathOr([], ['attributeGroups'], asset);
    const medium = media.filter((med: MediaItem) => med.fieldId === asset.id);

    return (
      <div styleName="asset-field-block">
        <div styleName="asset-field-header">
          <div styleName="left-asset-header">
            {leftIcon}
            <div styleName="asset-title-block">
              <div styleName="asset-name">{name}</div>
              <div styleName="asset-type">{asset.assetType}</div>
            </div>
          </div>
          <div styleName="right-asset-header">
            <FileUploadButton
              onChange={this.assetFileUpload}
              isEditable={isEditable}
              isUploadDisabled={!isEditable || preview || isUploadDisabled}
              asset
            />
            <FontIconButton
              style={styles.icon}
              onClick={this.toggleCollapse}
              color={colors.neutral.gray}
              icon={collapseIcon}
            />
          </div>
        </div>
        {open && (
          <div styleName="asset-content-block">
            {attributeGroups.map((group: HydratedAttributeGroupsItem) => {
              return (
                <InstanceAttributeGroup
                  isEditable={isEditable}
                  attributeGroup={group}
                  key={group.name}
                  assetId={asset.id}
                  fieldId={fieldId}
                  progressiveUpdateFormInstanceAttribute={
                    progressiveUpdateFormInstanceAttribute
                  }
                  savingFormInstance={savingFormInstance}
                />
              );
            })}
            {!R.isEmpty(medium) && (
              <div styleName="files-section-title">Files</div>
            )}
            <div styleName="file-tile-container">
              {medium.map((mediaItem: MediaItem) => (
                <CarouselWrapper
                  media={media}
                  selectedMediaId={mediaItem.id}
                  removeFile={removeFile}
                  subjectId={asset.id}
                  renameMedia={renameMedia}
                  canUpdateMedia={isEditable}
                  canDeleteMedia={isEditable}
                >
                  <FileUploadTile key={mediaItem.id} media={mediaItem} />
                </CarouselWrapper>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InstanceAssetField;
