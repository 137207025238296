import React, { useContext } from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import TaskContext from '@atom/components/workOrderPreview/taskView/TaskContext';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import './taskView.css';

const TaskActualCost = () => {
  const { task } = useContext(TaskContext);

  const tooltipContent = (
    <div styleName="tooltip-content">
      <strong>How is Actual Cost calculated?</strong>
      <div>Labor cost + Inventory Cost</div>
    </div>
  );

  const actualCost = hasRolePermissions(ROLE_SETS.MANAGER)
    ? numberToLocaleString(task.actualCost)
    : '-';

  return (
    <div styleName="cost-content-right">
      <div styleName="label-container">
        <div styleName="label">Actual Cost</div>
        <Tooltip content={tooltipContent} position="bottom-left" />
      </div>
      <div>{actualCost}</div>
    </div>
  );
};

export default TaskActualCost;
