// This hook is used to update the chart data when the source data changes
// - API response is passed in as dataRaw when updated
// - Returns formatted data collection with other data points needed for the
//   Recharts tooltip and legend components

import { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';

import { GroupKeys } from './expendituresChartUtils';

export const usePrepChartData = dataRaw => {
  const { budget } = useContext(BudgetDetailContext);

  const [dataFields, setDataFields] = useState<string[]>([]);
  const [actualFields, setActualFields] = useState<string[]>([]);
  const [remainingFields, setRemainingFields] = useState<string[]>([]);
  const [maxVal, setMaxVal] = useState<number>(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isNilOrEmpty(dataRaw)) {
      return;
    }

    const dataPrepped = dataRaw.map(monthData => ({
      name: monthData.month,
      [`${budget.name} ${GroupKeys.ACTUAL}`]: monthData.actualCost,
      [`${budget.name} ${GroupKeys.REMAINING}`]: monthData.totalRemaining,
    }));

    setData(dataPrepped);

    const dataFieldsCurrent = R.without(
      ['name'],
      R.keys(R.pathOr({}, [0], dataPrepped)),
    );
    setDataFields(dataFieldsCurrent);
    setActualFields(
      dataFieldsCurrent.filter(field => R.endsWith(GroupKeys.ACTUAL, field)),
    );

    setRemainingFields(
      dataFieldsCurrent.filter(field => R.endsWith(GroupKeys.REMAINING, field)),
    );

    const getValues = (field: string): number[] =>
      dataPrepped.map(item => item[field]);

    const allVals: number[] = dataFieldsCurrent.reduce(
      (acc, field): number[] => [...acc, ...getValues(field)],
      [],
    );
    setMaxVal(Math.max(...allVals));
  }, [dataRaw]);

  return { dataFields, actualFields, remainingFields, maxVal, data };
};
