import React from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import colors from '@atom/styles/colors';

import './workTemplateChildSettings.css';

const ChildSettingsTitle = () => {
  const tooltipContent = (
    <div styleName="tooltip">
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is disabled by default, the child work template must
          inherit content from that section as it appears on the parent, without
          permission to modify the inherited content.
        </div>
      </div>
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is enabled, the child work template has the option to
          select that section to inherit its content as it appears on the
          parent, without permission to modify the inherited content.
        </div>
      </div>
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is enabled but not selected, the child work template
          will not inherit any content from the parent for that section.
        </div>
      </div>
      <div styleName="tooltip-row">
        In all scenarios, the child work template can add additional fields,
        files, or items to any of the sections.
      </div>
    </div>
  );

  return (
    <div styleName="header-container">
      <div styleName="title">Child Work Template Settings</div>
      <Tooltip
        content={tooltipContent}
        iconSize="1.125rem"
        iconColor={colors.neutral.gray}
        position="bottom-left"
      />
    </div>
  );
};

export default ChildSettingsTitle;
