export enum WorkOrderColumnProperty {
  WORK_FIELD = 'WORK_FIELD',
  TASK_FIELD = 'TASK_FIELD',
  PRIORITY = 'PRIORITY',
  STATUS = 'STATUS',
  INVENTORY_NAME = 'INVENTORY_NAME',
  DUE_DATE = 'DUE_DATE',
  ASSIGNED_TO = 'ASSIGNED_TO',
  COMPLETED_DATE = 'COMPLETED_DATE',
  COMPLETED_BY = 'COMPLETED_BY',
  NUM_TASKS = 'NUM_TASKS',
  NUM_FORMS = 'NUM_FORMS',
  WORK_TYPE = 'WORK_TYPE',
  CREATED_DATE = 'CREATED_DATE',
  CREATED_BY = 'CREATED_BY',
  START_DATE = 'START_DATE',
  DURATION = 'DURATION',
}

export type WorkOrderColumn = {
  property: WorkOrderColumnProperty;
  taskId?: string;
  taskName?: string;
  fieldId?: string;
  label: string;
  sortable: boolean;
};

export type WorkOrderColumnsConnection = {
  totalCount: number;
  workOrderColumns: WorkOrderColumn[];
};

export type WorkOrderColumnsUpdateInput = {
  workTemplateId: string;
  workOrderColumns: WorkOrderColumn[];
};
