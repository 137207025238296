import * as React from 'react';
import * as R from 'ramda';

import Checkbox from '@atom/components/common/Checkbox';
import TextField from '@atom/components/common/TextField';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaActions } from '@atom/types/actions';
import iconUtilities from '@atom/utilities/iconUtilities';

import './elements.css';

const RIGHT_ARROW = 'right-arrow';
const DOWN_ARROW = 'down-arrow';
const VISIBLE = 'inherit';
const HIDDEN = 'none';

interface Element {
  id: string;
  name: string;
  elementGroups: any[];
  hasChildren: boolean;
  assetType: string;
  markerId?: number;
}

interface Props {
  element: Element;
  isChecked?: boolean;
  onClick: (id: string) => void;
  value?: number;
  inventorySchemaActions: InventorySchemaActions;
  schemaQuantities: object;
  selectedItems: Set<string>;
  elementPath?: any[];
  loading?: boolean;
  parentId?: string;
  elementExistsOnAsset?: boolean;
}

const styles = {
  rowStyle: {
    paddingLeft: '25px',
  },
  input: {
    color: colors.neutral.black,
    whiteSpace: 'normal',
    padding: '0.25em 0',
  },
  inputStyle: {
    textAlign: 'center',
  },
  imageStyle: {
    marginRight: '10px',
  },
  fhwaStyle: {
    color: colors.neutral.gray,
    marginRight: '10px',
  },
};

class FhwaSchemaElementListRow extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      icon: iconUtilities.leftIcon(RIGHT_ARROW),
      expanded: HIDDEN,
    };
  }

  retrieveTreeNode = () => {
    const { element, elementPath, inventorySchemaActions } = this.props;

    if (!element.elementGroups) {
      const data = {
        id: R.last(element.id.split('.')),
        elementPath,
      };

      return inventorySchemaActions.retrieveInventorySchemaTreeNode(data);
    }
    return false;
  };

  toggleSection = () => {
    // @ts-ignore
    const { expanded } = this.state;

    if (expanded === HIDDEN) {
      this.setState({
        icon: iconUtilities.leftIcon(DOWN_ARROW),
        expanded: VISIBLE,
      });
      this.retrieveTreeNode();
    } else {
      this.setState({
        icon: iconUtilities.leftIcon(RIGHT_ARROW),
        expanded: HIDDEN,
      });
    }
  };

  render() {
    const {
      element,
      onClick,
      isChecked,
      value,
      loading,
      inventorySchemaActions,
      elementPath,
      selectedItems,
      schemaQuantities,
      parentId,
      elementExistsOnAsset,
    } = this.props;
    // @ts-ignore
    const { icon, expanded } = this.state;
    const isDisabled =
      (parentId && !selectedItems.has(parentId)) || elementExistsOnAsset;

    const leftIcon = iconUtilities.buildElementIcon(
      element.markerId,
      styles,
      false,
    );

    return (
      <div style={styles.rowStyle}>
        <div
          styleName={`element-list-row${isDisabled ? ' disabled' : ''} modal`}
        >
          {element.hasChildren && (
            <span styleName="schema-element-icon" onClick={this.toggleSection}>
              {loading ? <Progress thickness={2} size={16} /> : icon}
            </span>
          )}
          <div>
            <Checkbox
              isChecked={isChecked}
              toggleCheck={() => onClick(element.id)}
              disabled={isDisabled}
            />
          </div>
          <div
            styleName="element-label"
            onClick={!isDisabled ? () => onClick(element.id) : () => {}}
          >
            {leftIcon}
            {element.name}
          </div>
          <div>
            <TextField
              name={element.id}
              step="1"
              value={value || 1}
              fullWidth
              style={styles.input}
              inputStyle={styles.inputStyle}
              disabled
              underlineStyle={{ borderColor: colors.neutral.silver }}
            />
          </div>
        </div>
        <div
          styleName="sub-element-list-container"
          style={{ display: expanded }}
        >
          {element.elementGroups &&
            element.elementGroups.map((elementGroup, elementGroupIndex) =>
              elementGroup.elements.map((subElement, subElementIndex) => {
                const subElementWithPathId = {
                  ...subElement,
                  id: `${element.id}.${subElement.id}`,
                };

                return (
                  <FhwaSchemaElementListRow
                    isChecked={selectedItems.has(subElementWithPathId.id)}
                    schemaQuantities={schemaQuantities}
                    selectedItems={selectedItems}
                    key={subElementWithPathId.id}
                    element={subElementWithPathId}
                    onClick={onClick}
                    loading={loading}
                    value={schemaQuantities[subElementWithPathId.id]}
                    inventorySchemaActions={inventorySchemaActions}
                    parentId={element.id}
                    elementPath={[
                      ...elementPath,
                      'elementGroups',
                      elementGroupIndex,
                      'elements',
                      subElementIndex,
                    ]}
                  />
                );
              }),
            )}
        </div>
      </div>
    );
  }
}

export default FhwaSchemaElementListRow;
