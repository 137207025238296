import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Box, SelectChangeEvent, Toolbar } from '@mui/material';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as organizationActionCreators from '@atom/actions/organizationActions';
import * as userActionCreators from '@atom/actions/userActions';
import Checkbox from '@atom/components/common/Checkbox';
import Logo from '@atom/components/common/navigation/Logo';
import TextField from '@atom/components/common/TextField';
import { Button, Icon, Progress, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import layoutStyles from '@atom/styles/layout';
import { OrganizationActions, UserActions } from '@atom/types/actions';
import { EventType } from '@atom/types/event';
import { ReduxStore } from '@atom/types/store';
import accountUtilities from '@atom/utilities/accountUtilities';
import history from '@atom/utilities/history';

import LoginFooter from './LoginFooter';

import './login.css';

const { MenuItem } = Select;

const navBarStyle = {
  height: layoutStyles.topNavHeight,
  backgroundColor: colors.neutral.white,
  boxShadow: '0 2px 4px 1px',
  color: colors.utility.boxShadow,
  position: 'fixed',
  top: 0,
};

const styles = {
  titleStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  activeIcon: {
    fontSize: '24px',
    color: colors.neutral.white,
  },
  input: {},
  inputStyle: {},
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface ReduxDispatchProps {
  organizationActions: OrganizationActions;
  userActions: UserActions;
}

interface ReduxStateProps {
  loading: boolean;
}

type Props = ReduxDispatchProps & ReduxStateProps;

interface ErrorsState {
  tenant: string;
  terms: string;
  industry: string;
}

interface State {
  email: string;
  tenant: string;
  industry: string;
  checked: boolean;
  errors: ErrorsState;
}

const INDUSTRIES = [
  'Preventative Maintenance',
  'Mobile Workforce',
  'Transportation | Traffic',
  'Utilities',
  'Fleet Management',
  'Emergency Response',
  'Equipment Management',
  'Rail | Transit',
  'Stormwater Management',
  'Facility Management',
  'Other',
];

const DEV_TENANT_NAME_VALIDATION = new RegExp('^dev\\-[-a-zA-Z]+$');
const QA_TENANT_NAME_VALIDATION = new RegExp('^qa\\-[-a-zA-Z]+$');
const UAT_TENANT_NAME_VALIDATION = new RegExp('^uat\\-[-a-zA-Z]+$');
const DEFAULT_TENANT_NAME_VALIDATION = new RegExp('^[a-zA-Z][-a-zA-Z]+$');

class TenantCreation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state.email = this.getEmailAccount();
  }

  state = {
    email: '',
    tenant: '',
    industry: '',
    checked: false,
    errors: {
      tenant: '',
      terms: '',
      industry: '',
    },
  };

  componentDidMount() {
    if (!accountUtilities.isEmailVerified()) {
      history.push('/verify-email');
    }
  }

  getEmailAccount = (): string => {
    const email = accountUtilities.getLoggedInEmail();
    if (!email) {
      history.push('/login');
    }

    return email;
  };

  onChange = (event: EventType) => {
    const { value } = event.target;
    this.setState({ tenant: value });
  };

  onIndustryChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    this.setState({ industry: value });
  };

  onToggleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  getCurrentHost = (): string => {
    return window.location.hostname.split('.')[2]
      ? R.pipe(
          R.pathOr('www.atomapp.com', ['location', 'hostname']),
          R.split('.'),
          R.drop(1),
          R.join('.'),
        )(window)
      : R.pipe(R.pathOr('www.atomapp.com', ['location', 'hostname']))(window);
  };

  isValidTenantName = (host: string): boolean => {
    const { tenant } = this.state;

    if (host === 'dev-atom' || host === 'local-atom') {
      return DEV_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (host === 'qa-atom') {
      return QA_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (host === 'uat-atom') {
      return UAT_TENANT_NAME_VALIDATION.test(tenant);
    }

    if (
      host === 'atomapp' &&
      (DEV_TENANT_NAME_VALIDATION.test(tenant) ||
        QA_TENANT_NAME_VALIDATION.test(tenant) ||
        UAT_TENANT_NAME_VALIDATION.test(tenant))
    ) {
      return false;
    } else if (host === 'atomapp') {
      return DEFAULT_TENANT_NAME_VALIDATION.test(tenant);
    }

    return false;
  };

  isValid = (): boolean => {
    const host = window.location.hostname.split('.')[2]
      ? window.location.hostname.split('.')[1]
      : window.location.hostname.split('.')[0];

    const tenant = !this.isValidTenantName(host)
      ? 'Please enter a valid tenant name.  The name cannot include any numbers or special characters.'
      : '';

    const terms = !this.state.checked
      ? 'Please agree to the terms before continuing.'
      : '';
    const industry = !this.state.industry ? 'Please select an industry.' : '';

    this.setState({
      errors: {
        ...this.state.errors,
        tenant,
        terms,
        industry,
      },
    });

    return !tenant && !terms && !industry;
  };

  submit = () => {
    const { organizationActions } = this.props;
    const { tenant, industry } = this.state;

    if (this.isValid()) {
      organizationActions.requestTenantCreation({
        name: tenant.toLocaleLowerCase(),
        industry,
      });
    }
  };

  getErrorText = () => {
    const { errors } = this.state;

    if (errors.tenant) {
      return <div styleName="tenant-creation-error-text">{errors.tenant}</div>;
    }

    if (errors.terms) {
      return <div styleName="tenant-creation-error-text">{errors.terms}</div>;
    }

    if (errors.industry) {
      return (
        <div styleName="tenant-creation-error-text">{errors.industry}</div>
      );
    }

    return <div />;
  };

  logout = () => {
    const { userActions } = this.props;
    userActions.triggerLogout();
  };

  render() {
    const { loading } = this.props;
    const { email, tenant, industry, checked } = this.state;
    const errors = this.getErrorText();
    const host = this.getCurrentHost();

    const content = loading ? (
      <div styleName="spinner-container">
        <Progress />
      </div>
    ) : (
      <React.Fragment>
        {errors}
        <div styleName="text-field-container creation">
          This is going to be your workspace URL.
        </div>
        <div styleName="tenant-creation-input-container">
          <TextField
            name="tenant"
            onChange={this.onChange}
            value={tenant}
            fullWidth
            style={styles.input}
            inputStyle={styles.inputStyle}
            underlineStyle={{ borderColor: colors.neutral.silver }}
          />
          <div styleName="tenant-creation-input-label">{`.${host}`}</div>
        </div>
        <div styleName="tenant-creation-industry-container">
          <Select
            fullWidth
            label="Select Your Industry"
            style={styles.textFieldStyle}
            onChange={this.onIndustryChange}
            value={industry}
          >
            {INDUSTRIES.map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div styleName="tenant-creation-checkbox-container">
          <Checkbox isChecked={checked} toggleCheck={this.onToggleCheck} />
          <div styleName="tenant-creation-terms-content">
            I agree to the Terms of Use and Privacy Policy.
          </div>
        </div>
        <div styleName="tenant-creation-button-container">
          <Button color="primary" variant="contained" onClick={this.submit}>
            Go to Atom
          </Button>
        </div>
        <div styleName="workspace-selection-container">
          <div>Already have a workspace?</div>
          <Link to="/tenant-entry">
            <div styleName="tenant-selection-link">Join a workspace</div>
          </Link>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <AppBar style={navBarStyle}>
          <Toolbar>
            <div>
              <Logo />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <div styleName="nav-right-container">
              <div styleName="nav-right-text">{email}</div>
              <div styleName="nav-right-login" onClick={this.logout}>
                Sign Out
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div styleName="container">
          <div styleName="auth-background complete" />
          <div styleName="heading-text">Create your Atom environment</div>
          <div styleName="registration-icon-container">
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                person
              </Icon>
            </div>
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                email
              </Icon>
            </div>
            <div styleName="registration-icon active">
              <Icon className="material-icons" style={styles.activeIcon}>
                business
              </Icon>
            </div>
          </div>
          <div styleName="icon-horizontal-bar-container">
            <div styleName="icon-horizontal-bar-half active" />
            <div styleName="icon-horizontal-bar-half active" />
          </div>
          {content}
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingTenantCreation,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  organizationActions: bindActionCreators(organizationActionCreators, dispatch),
  userActions: bindActionCreators(userActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantCreation);
