import React from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';

import './workTemplateTaskDetail.css';

const TaskActualCost = () => {
  const tooltipContent = (
    <div styleName="tooltip-content">
      <strong>How is Actual Cost calculated?</strong>
      <div>Labor cost + Inventory Cost</div>
    </div>
  );

  return (
    <div styleName="cost-content-right">
      <div styleName="label-container">
        <div styleName="label">Actual Cost</div>
        <Tooltip content={tooltipContent} position="bottom-left" />
      </div>
      <div>-</div>
    </div>
  );
};

export default TaskActualCost;
