import React from 'react';
import ReactDOMServer from 'react-dom/server';
import along from '@turf/along';
import bbox from '@turf/bbox';
import turfBooleanContains from '@turf/boolean-contains';
import turfBooleanCrosses from '@turf/boolean-crosses';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import turfCircle from '@turf/circle';
import turfDistance from '@turf/distance';
import length from '@turf/length';
import ngeohash from 'ngeohash';
import queryString from 'query-string';
import * as R from 'ramda';

import AssetGroupMarker from '@atom/components/common/map/markers/AssetGroupMarker';
import MapClusterMarker from '@atom/components/common/map/markers/ClusterMarker';
import WorkOrderGroupMarker from '@atom/components/common/map/markers/WorkOrderGroupMarker';
import UserThumbnail from '@atom/components/common/UserThumbnail';
import { Layers } from '@atom/components/mapPortal/hooks/layersHook';
import { Icon } from '@atom/mui';
import { GoogleMapsLocation } from '@atom/types/gmapsLocation';
import {
  MapCenterAndZoomParams,
  MapEditMetadata,
  MapEditMetadataType,
  MapParams,
  MapShape,
  MapShapeType,
} from '@atom/types/map';
import { Preferences } from '@atom/types/preferences';
import { MAP_ENDPOINT } from '@atom/utilities/endpoints';
import markerUtilities from '@atom/utilities/markerUtilities';

import { getColorFromColorId } from './colorUtilities';
import schemaUtilities from './schemaUtilities';
import { getWorkOrderStatusColor } from './workOrderStatusUtilities';

// Center of United States
export const DEFAULT_MAP_CENTER = { lat: 39.8283, lng: -98.5795 };
// Zoom to show entire United States
export const DEFAULT_MAP_ZOOM = 5;
export const GOOGLE_MAPS_API_KEY = 'AIzaSyA6yrGnEFLHQhwjNqF2uFlsZi4pwqtRQDg';
export const GOOGLE_MAPS_LIBRARIES = ['places', 'geometry', 'drawing'];

export const zipCoordinatePair = R.zipObj(['lng', 'lat']);

export const unzipCoordinatePair = coordinates => [
  coordinates.lng || 0,
  coordinates.lat || 0,
];

export const getGeoJSONLocation = (location: { lat: number; lng: number }) => {
  return {
    type: 'Point',
    coordinates: [
      R.pathOr(0, ['lng'])(location),
      R.pathOr(0, ['lat'])(location),
    ],
  };
};

export const generatePath = coordinates => coordinates.map(zipCoordinatePair);

export const getLineStringCenter = shape => {
  const line = {
    type: 'Feature',
    properties: {},
    geometry: shape,
  };

  // @ts-ignore
  const lengthToCenter = length(line) / 2;
  // @ts-ignore
  const center = along(line, lengthToCenter);

  return center;
};

export const getGoogleShapeCenter = shape => {
  if (R.isNil(shape) || R.isEmpty(shape)) {
    // Return a generic center
    return { lng: 0, lat: 0 };
  }

  if (shape.type === 'Point') {
    return zipCoordinatePair(shape.coordinates);
  }

  if (shape.type === 'LineString') {
    return zipCoordinatePair(getLineStringCenter(shape).geometry.coordinates);
  }

  // Return a generic center
  return { lng: 0, lat: 0 };
};

export const getFitBoundsFromShape = shape => {
  if (R.isNil(shape) || R.isEmpty(shape)) {
    return null;
  }

  if (shape.type === 'Point') {
    return null;
  }

  if (shape.type === 'LineString') {
    return bbox(shape);
  }

  return null;
};

export const getGeoJsonFromMapBounds = map => {
  try {
    const bounds = map.getBounds().toJSON();
    const northwest = [bounds.west, bounds.north];
    const northeast = [bounds.east, bounds.north];
    const southeast = [bounds.east, bounds.south];
    const southwest = [bounds.west, bounds.south];
    return {
      type: 'Polygon',
      coordinates: [[northeast, northwest, southwest, southeast, northeast]],
    };
  } catch (error) {
    throw error;
  }
};

export const urlValueToLatLng = (urlValue: string): google.maps.LatLngLiteral =>
  // @ts-ignore
  R.pipe(R.split(','), R.map(Number), R.zipObj(['lat', 'lng']))(urlValue);

export const getStaticImage = (entityLocation, size = '340x160') => {
  const center = getGoogleShapeCenter(entityLocation);

  const zoom = 18;
  const maptype = 'satellite';
  const baseEndpoint = 'https://maps.googleapis.com/maps/api/staticmap';

  const url = `${baseEndpoint}?center=${center.lat},${center.lng}&zoom=${zoom}&size=${size}&maptype=${maptype}&format=png&visual_refresh=true&key=${GOOGLE_MAPS_API_KEY}`;

  return url;
};

// getMapCenterAndZoomParams sets the initial map params if they do not exist
// or simply passes through the current map params

// Priority for setting map center is as follows:
// 1. User's current location
// 2. Map center from tenant preferences
// 3. Default center of United States

// Priority for setting zoom level is as follows:
// 1. Zoom level from tenant preferences
// 2. Default zoom level of 5
export const getMapCenterAndZoomParams = (
  map: google.maps.Map,
  queryParams: MapCenterAndZoomParams | MapParams,
  preferences: Preferences,
  currentUserLocation: GoogleMapsLocation,
): MapCenterAndZoomParams => {
  const preferencesZoom = preferences?.map?.zoom;

  if (!queryParams.center || !queryParams.zoom) {
    return {
      zoom: preferencesZoom || DEFAULT_MAP_ZOOM,
      center: `${currentUserLocation.lat},${currentUserLocation.lng}`,
    };
  }

  return {
    ...queryParams,
    zoom: map.getZoom(),
    center: map.getCenter().toUrlValue(),
  };
};

export const getMapEditMetadataIcon = (
  data: any,
  type: MapEditMetadataType,
): React.ReactNode => {
  switch (type) {
    case MapEditMetadataType.ASSET:
      return schemaUtilities.getSchemaIconFromSchemaOrAsset(data);
    case MapEditMetadataType.WORK_ORDER:
      return <Icon style={{ height: '34px', width: '34px' }}>work</Icon>;
    case MapEditMetadataType.USER:
      return <UserThumbnail image={data.photoUrl} alt={data.name} />;
    default:
      return <div />;
  }
};

export const getMapEditMetadataName = (
  data: any,
  type: MapEditMetadataType,
): string => {
  switch (type) {
    case MapEditMetadataType.ASSET:
      return data?.name;
    case MapEditMetadataType.WORK_ORDER:
      return data?.name;
    case MapEditMetadataType.USER:
      return `${data.firstName} ${data.lastName}`;
    default:
      return '';
  }
};

export const getMapEditMetadata = (
  data: any,
  canEdit: boolean,
  type: MapEditMetadataType,
): MapEditMetadata => {
  return {
    id: data?.id,
    type,
    icon: getMapEditMetadataIcon(data, type),
    name: getMapEditMetadataName(data, type),
    address: data?.address,
    location: data?.location,
    markerId: data?.markerId,
    statusId: data?.statusId,
    canEdit,
  };
};

export const getGeoJSONFromGoogleMapShape = (shape: MapShape): any => {
  switch (shape?.type) {
    case MapShapeType.MARKER: {
      return {
        type: 'Point',
        coordinates: [
          // @ts-ignore
          shape.getPosition().lng(),
          // @ts-ignore
          shape.getPosition().lat(),
        ],
      };
    }
    case MapShapeType.POLYLINE: {
      return {
        type: 'LineString',
        coordinates: shape
          // @ts-ignore
          .getPath()
          .getArray()
          .map(item => {
            return [item.lng(), item.lat()];
          }),
      };
    }
    default:
      return {
        type: 'Point',
        coordinates: [0, 0],
      };
  }
};

export const mergeSpec = R.curry((spec, value) =>
  R.converge(R.mergeRight, [R.identity, R.applySpec(spec)])(value),
);

const createElement = R.curryN(2, React.createElement);

export const buildMapDataRequest = (layers: Layers, urlParams) =>
  urlParams.zoom && layers.assetSchemas && urlParams.coordinates
    ? queryString.stringifyUrl(
        {
          url: MAP_ENDPOINT,
          query: {
            zoomLevel: Number(urlParams.zoom) > 20 ? '20' : urlParams.zoom,
            coordinates: urlParams.coordinates,
            includeUsers: layers.users.toString(),
            includeWorkOrders: Boolean(
              layers.work &&
                (layers.workStatusIds.size ||
                  layers.workTemplateIds.size ||
                  layers.workTemplateFolderIds.size),
            ).toString(),
            includeAssets: Boolean(
              layers.assets && layers.assetSchemas.size,
            ).toString(),
            schemaIds: Array.from(layers.assetSchemas),
            statusIds: Array.from(layers.workStatusIds),
            workTemplateIds: Array.from(layers.workTemplateIds),
            workTemplateFolderIds: Array.from(layers.workTemplateFolderIds),
            clusterDensity: layers.clusterDensity,
          },
        },
        { arrayFormat: 'comma', encode: false },
      )
    : null;

const groupEntitiesList = type =>
  R.pipe(
    R.propOr([], type),
    // @ts-ignore
    R.groupBy(R.pipe(R.prop('location'), JSON.stringify)),
    R.values,
  );

// @ts-ignore
const groupAssetsAndWorkOrders = R.applySpec({
  assets: data =>
    R.pipe(
      groupEntitiesList('assets'),
      R.filter(R.pipe(R.length, R.gte(1))),
      R.unnest,
    )(data) || [],
  assetGroups: data =>
    R.pipe(
      groupEntitiesList('assets'),
      R.filter(R.pipe(R.length, R.lt(1))),
    )(data) || [],
  workOrders: data =>
    R.pipe(
      groupEntitiesList('workOrders'),
      R.filter(R.pipe(R.length, R.gte(1))),
      R.unnest,
    )(data) || [],
  workOrderGroups: data =>
    R.pipe(
      groupEntitiesList('workOrders'),
      R.filter(R.pipe(R.length, R.lt(1))),
    )(data) || [],
  users: R.pathOr([], ['users']),
  clusters: R.pathOr([], ['clusters']),
  locations: R.pathOr([], ['locations']),
});

const encodeSvg = reactElement =>
  `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(reactElement),
  )}`;

export const getClusterIcon = R.applySpec({
  url: R.pipe(
    // @ts-ignore
    createElement(MapClusterMarker),
    encodeSvg,
  ),
  anchor: R.pipe(
    R.prop('count'),
    R.ifElse(R.lt(100), R.always({ x: 45, y: 45 }), R.always({ x: 35, y: 35 })),
  ),
});

const getGroupId = R.pipe(R.pluck('id'), R.join(','));

export const bboxToGmapBounds = R.zipObj(['south', 'west', 'north', 'east']);

export const getDistanceBetweenMarkers = R.useWith(turfDistance, [
  getGeoJSONLocation,
  getGeoJSONLocation,
]);

export const getBoundsFromGeohash = (
  id: string,
): google.maps.LatLngBoundsLiteral =>
  // @ts-ignore
  R.pipe(ngeohash.decode_bbox, bboxToGmapBounds)(id);

export const getCurrentUserLocation = (): Promise<{
  lat: number;
  lng: number;
} | null> =>
  // eslint-disable-next-line consistent-return
  new Promise(resolve => {
    if (!navigator.geolocation) {
      return resolve(null);
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = R.pathOr(0, ['coords', 'latitude'])(position);
        const lng = R.pathOr(0, ['coords', 'longitude'])(position);
        resolve({ lat, lng });
      },
      () => resolve(null),
    );
  });

const CURSOR_RADIUS = 40;

const pointWithin = R.curry(R.flip(turfBooleanPointInPolygon));
const crosses = R.curry(turfBooleanCrosses);
const contains = R.curry(turfBooleanContains);

export const getGrabShape = (map: google.maps.Map, mapData) => (
  event: google.maps.MouseEvent,
) => {
  const clickPosition = map.getProjection().fromLatLngToPoint(event.latLng);
  const clickJSON = event.latLng.toJSON();

  const pixelSize = Math.pow(2, -map.getZoom());

  const radiusPosition = clickPosition.x - CURSOR_RADIUS * pixelSize;
  const radiusPoint = new google.maps.Point(radiusPosition, clickPosition.y);
  const radiusLatLng = map.getProjection().fromPointToLatLng(radiusPoint);

  const mileRadius = getDistanceBetweenMarkers(
    clickJSON,
    radiusLatLng.toJSON(),
  );
  const circle = turfCircle(getGeoJSONLocation(clickJSON), mileRadius);

  const active = R.pipe(
    R.pick(['assets', 'workOrders', 'users']),
    R.values,
    R.unnest,
    // @ts-ignore
    R.filter(
      R.pipe(
        R.prop('location'),
        // @ts-ignore
        R.cond([
          // @ts-ignore
          [R.propEq('type', 'Point'), pointWithin(circle)],
          [R.T, R.anyPass([crosses(circle), contains(circle)])],
        ]),
      ),
    ),
    // @ts-ignore
    R.pluck('id'),
    R.join(','),
    // @ts-ignore
  )(mapData);

  return active;
};

// @ts-ignore
const haveSameIds = R.useWith(R.pipe(R.intersection, R.length, R.lt(0)), [
  R.pipe(R.split(',')),
  R.pipe(R.prop('id'), R.split(',')),
]);
const isMarkerActive = ({ hoverId = '', activeIds = '' }) =>
  haveSameIds(hoverId || activeIds);
const isMarkerDisabled = ({ disabledIds = '' }) =>
  haveSameIds(disabledIds || '');

// @ts-ignore
export const formatMapData = sharedProps =>
  R.pipe(
    // @ts-ignore
    groupAssetsAndWorkOrders,
    // @ts-ignore
    R.evolve({
      // @ts-ignore
      assets: R.map(
        R.applySpec({
          isActive: isMarkerActive(sharedProps),
          disabled: isMarkerDisabled(sharedProps),
          position: R.pipe(R.prop('location'), getGoogleShapeCenter),
          path: R.ifElse(
            R.pathEq(['location', 'type'], 'LineString'),
            R.pipe(R.path(['location', 'coordinates']), generatePath),
            R.always(null),
          ),

          icon: {
            url: R.ifElse(
              isMarkerActive(sharedProps),
              R.pipe(
                R.prop('markerId'),
                markerUtilities.getActiveAssetIconFromSchemaMarkerId,
              ),
              R.pipe(
                R.prop('markerId'),
                markerUtilities.getInactiveAssetIconFromSchemaMarkerId,
              ),
            ),
            anchor: R.ifElse(
              isMarkerActive(sharedProps),
              R.always({ x: 20, y: 36 }),
              R.always({ x: 17, y: 17 }),
            ),
          },
          color: R.pipe(R.prop('markerId'), getColorFromColorId),
          id: R.prop('id'),
          clickable: R.always(sharedProps.clickable),
        }),
      ),
      clusters: R.map(
        R.applySpec({
          position: R.pipe(R.prop('location'), getGoogleShapeCenter),
          icon: getClusterIcon,
          id: R.prop('id'),
        }),
      ),
      workOrders: R.map(
        R.applySpec({
          id: R.prop('id'),
          position: R.pipe(R.prop('location'), getGoogleShapeCenter),
          isActive: isMarkerActive(sharedProps),
          disabled: isMarkerDisabled(sharedProps),
          icon: {
            url: R.pipe(
              // @ts-ignore
              R.converge(markerUtilities.getWorkOrderSvg, [
                R.prop('statusId'),
                isMarkerActive(sharedProps),
              ]),
            ),
            anchor: R.always({
              x: 20,
              y: 32,
            }),
          },
          size: R.always({
            x: 18,
            y: 40,
          }),
          clickable: R.always(sharedProps.clickable),
          zIndex: R.ifElse(
            isMarkerActive(sharedProps),
            R.always(504),
            R.always(500),
          ),
        }),
      ),
      users: R.map(
        R.applySpec({
          id: R.prop('id'),
          position: R.pipe(R.prop('location'), getGoogleShapeCenter),
          isActive: isMarkerActive(sharedProps),
          disabled: isMarkerDisabled(sharedProps),
          icon: {
            url: R.pipe(
              isMarkerActive(sharedProps),
              markerUtilities.getUserSvg,
            ),
            anchor: R.always({
              x: 14,
              y: 34,
            }),
          },
          clickable: R.always(sharedProps.clickable),
          zIndex: R.ifElse(
            isMarkerActive(sharedProps),
            R.always(505),
            R.always(501),
          ),
        }),
      ),
      assetGroups: R.map(
        R.applySpec({
          id: getGroupId,
          isActive: R.pipe(
            getGroupId,
            R.objOf('id'),
            isMarkerActive(sharedProps),
          ),
          disabled: R.pipe(
            getGroupId,
            R.objOf('id'),
            isMarkerDisabled(sharedProps),
          ),
          icon: {
            url: R.pipe(
              R.applySpec({
                count: R.length,
                isActive: R.pipe(
                  getGroupId,
                  R.objOf('id'),
                  isMarkerActive(sharedProps),
                ),
                color: R.pipe(R.path([0, 'markerId']), getColorFromColorId),
              }),
              createElement(AssetGroupMarker),
              encodeSvg,
            ),
            anchor: R.ifElse(
              R.pipe(getGroupId, R.objOf('id'), isMarkerActive(sharedProps)),
              R.always({ x: 20, y: 36 }),
              R.always({ x: 17, y: 17 }),
            ),
          },
          position: R.pipe(R.path([0, 'location']), getGoogleShapeCenter),
          path: R.ifElse(
            R.pathEq([0, 'location', 'type'], 'LineString'),
            R.pipe(R.path([0, 'location', 'coordinates']), generatePath),
            R.always(null),
          ),
          color: R.pipe(R.path([0, 'markerId']), getColorFromColorId),
          clickable: R.always(sharedProps.clickable),
        }),
      ),
      workOrderGroups: R.map(
        R.applySpec({
          id: getGroupId,
          isActive: R.pipe(
            getGroupId,
            R.objOf('id'),
            isMarkerActive(sharedProps),
          ),
          disabled: R.pipe(
            getGroupId,
            R.objOf('id'),
            isMarkerDisabled(sharedProps),
          ),
          icon: {
            url: R.pipe(
              R.applySpec({
                count: R.length,
                isActive: R.pipe(
                  getGroupId,
                  R.objOf('id'),
                  isMarkerActive(sharedProps),
                ),
              }),
              createElement(WorkOrderGroupMarker),
              encodeSvg,
            ),
            anchor: R.always({
              x: 25,
              y: 32,
            }),
          },
          position: R.pipe(R.path([0, 'location']), getGoogleShapeCenter),
          clickable: R.always(sharedProps.clickable),
        }),
      ),
      locations: R.map(
        R.applySpec({
          position: R.pipe(R.prop('geometry'), getGoogleShapeCenter),
          path: R.ifElse(
            R.pathEq(['geometry', 'type'], 'LineString'),
            R.pipe(R.path(['geometry', 'coordinates']), generatePath),
            R.always(null),
          ),
          icon: {
            url: R.pipe(
              R.converge(markerUtilities.getWorkOrderSvg, [
                R.prop('statusId'),
                R.ifElse(
                  R.propEq('isActive', true),
                  R.always(true),
                  R.always(false),
                ),
              ]),
            ),
            anchor: R.always({
              x: 20,
              y: 32,
            }),
          },
          color: R.pipe(R.prop('statusId'), getWorkOrderStatusColor),
          id: R.prop('id'),
          isActive: R.prop('isActive'),
          clickable: R.always(sharedProps.clickable),
        }),
      ),
    }),
  );
