import * as React from 'react';

import { Button, Icon, IconButton, Progress } from '@atom/mui';

import './attributeDetails.css';

interface Props {
  loading: boolean;
  name: string;
  onClick: (string) => void;
  onCollapse: (string) => void;
  collapsed: boolean;
  onCancel: (string) => void;
  onSave: (string) => void;
  isEdit?: boolean;
  hasPendingChanges?: boolean;
  canUpdate?: boolean;
  isDisabled?: boolean;
  typePreview?: boolean;
  errorAttributeIds?: string[];
}

const buildRightNavContainer = (
  name: string,
  onClick: (string) => void,
  onCollapse: (string) => void,
  collapsed: boolean,
  isEdit: boolean,
  onCancel: (string) => void,
  onSave: (string) => void,
  loading: boolean,
  canUpdate: boolean,
  isDisabled: boolean,
  typePreview: boolean,
  hasError?: boolean,
  // eslint-disable-next-line max-params
) => {
  if (isEdit) {
    return (
      <span styleName="attribute-group-header-container">
        <Button onClick={(): void => onCancel(name)} disabled={loading}>
          CANCEL
        </Button>
        {!typePreview && (
          <Button
            color="primary"
            disabled={loading || hasError}
            onClick={(): void => onSave(name)}
          >
            SAVE
          </Button>
        )}
        {loading && (
          <div styleName="spinner-container">
            <Progress size={25} thickness={2} />
          </div>
        )}
      </span>
    );
  }

  if (canUpdate) {
    return (
      <div>
        <IconButton disabled={isDisabled} onClick={(): void => onClick(name)}>
          <Icon color={isDisabled ? '#ccc' : 'inherit'}>edit</Icon>
        </IconButton>
        <IconButton onClick={(): void => onCollapse(name)}>
          <Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
        </IconButton>
      </div>
    );
  }

  return (
    <div>
      <IconButton onClick={(): void => onCollapse(name)}>
        <Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
      </IconButton>
    </div>
  );
};

const AttributeGroupHeader = ({
  name,
  onClick,
  onCollapse,
  collapsed,
  isEdit,
  onCancel,
  onSave,
  loading,
  hasPendingChanges,
  canUpdate = false,
  isDisabled = false,
  typePreview = false,
  errorAttributeIds = [],
}: Props) => {
  const hasError = errorAttributeIds.length > 0;

  const rightNavContainer = buildRightNavContainer(
    name,
    onClick,
    onCollapse,
    collapsed,
    isEdit,
    onCancel,
    onSave,
    loading,
    canUpdate,
    isDisabled,
    typePreview,
    hasError,
  );

  return (
    <div styleName="asset-group-header-container">
      <div styleName="change-indicator-container">
        <div styleName="asset-group-header-label">{name}</div>
        {hasPendingChanges && <div styleName="change-indicator" />}
      </div>
      <div styleName="asset-group-header-edit-icon">{rightNavContainer}</div>
    </div>
  );
};

export default AttributeGroupHeader;
