/* eslint-disable */
import * as R from 'ramda';
import * as React from 'react';

import { AutoComplete, MenuItem } from 'material-ui';
import Marker from 'material-ui/svg-icons/maps/place';
import { GoogleMapsLocation } from '@atom/types/gmapsLocation';

const styles = {
  textFieldStyle: {
    marginTop: '-0.3rem',
  },
  leftIconMarkerStyle: { width: '20px' },
  menuItem: {
    root: {
      fontSize: 13,
      overflow: 'hidden',
    },
    innerDivStyle: {
      paddingRight: 38,
      paddingLeft: 38,
    },
  },
  listStyle: {
    overflow: 'hidden',
  },
};

interface Props {
  initialValue?: string;
  componentRestrictions?: object;
  types?: any[];
  anchorOrigin?: object;
  disabled?: boolean;
  animated?: boolean;
  animation?: () => any;
  errorStyle?: object;
  errorText?: any;
  floatingLabelText?: string;
  floatingLabelStyle?: object;
  floatingLabelFixed?: boolean;
  underlineStyle?: object;
  fullWidth?: boolean;
  hintText?: string;
  listStyle?: object;
  maxSearchResults?: number;
  menuCloseDelay?: number;
  menuProps?: object;
  menuStyle?: object;
  onClose?: () => any;
  onNewRequest?: () => any;
  onUpdateInput?: () => any;
  open?: boolean;
  openOnFocus?: boolean;
  popoverProps?: object;
  searchText?: string;
  style?: object;
  targetOrigin?: object;
  textFieldStyle?: object;
  innerDivStyle?: object;
  menuItemStyle?: object;
  onSelect?: (place: GoogleMapsLocation, searchTerm?: string) => any;
  anchor?: any;
  popoverWidth?: string | number;
}

const isLatitude = num => isFinite(num) && Math.abs(num) <= 90;
const isLongitude = num => isFinite(num) && Math.abs(num) <= 180;

// TODO: [ATOM-3496] Add a robust test suite
class GooglePlaceAutoComplete extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchText: this.props.initialValue,
    };

    // @ts-ignore
    const google = window.google;
    // @ts-ignore
    this.geocoder = new google.maps.Geocoder();
    // @ts-ignore
    this.service = new google.maps.places.AutocompleteService(null);
  }

  getCurrentDataState = () => {
    // @ts-ignore
    return this.state.data;
  };

  getLatLgn = (locationID, cb) => {
    // @ts-ignore
    this.geocoder.geocode({ placeId: locationID }, (onSelect, status) => {
      cb(onSelect, status);
    });
  };

  updateInput = searchText => {
    if (!R.isEmpty(searchText)) {
      this.setState(
        {
          searchText,
        },
        () => {
          const outerScope = this;
          // @ts-ignore
          this.service.getPlacePredictions(
            {
              // @ts-ignore
              input: this.state.searchText,
            },
            predictions => {
              if (predictions) {
                outerScope.populateData(predictions);
              }
            },
          );
        },
      );
    } else {
      this.setState({ searchText });
    }
  };

  onNewRequest = (_, index) => {
    // @ts-ignore
    let dataItem = this.state.data[index];

    // @ts-ignore
    const [latitude, longitude] = this.state.searchText.split(',');

    if (
      isLatitude(parseFloat(latitude)) &&
      isLongitude(parseFloat(longitude))
    ) {
      return this.props.onSelect({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
    }

    if (!dataItem) {
      // @ts-ignore
      dataItem = this.state.data[0];
    }
    this.getLatLgn(dataItem.place_id, results => {
      this.props.onSelect(
        // @ts-ignore
        {
          // @ts-ignore
          lat: results[0].geometry.location.lat(),
          // @ts-ignore
          lng: results[0].geometry.location.lng(),
        },
        // @ts-ignore
        results[0].formatted_address,
      );

      this.setState({ searchText: results[0].formatted_address });
    });
  };

  getMenuItems = () => {
    // @ts-ignore
    const menuItems = this.state.data.map(item => {
      return {
        text: item.description,
        value: (
          <MenuItem
            style={styles.menuItem.root}
            innerDivStyle={
              this.props.innerDivStyle || styles.menuItem.innerDivStyle
            }
            primaryText={item.description}
            leftIcon={<Marker style={styles.leftIconMarkerStyle} />}
          />
        ),
      };
    });

    return menuItems;
  };

  populateData = array => {
    this.setState({ data: array });
  };

  getPopoverProps = () => {
    const { popoverWidth, anchor } = this.props;

    return {
      style: {
        ...(popoverWidth && {
          width: popoverWidth,
        }),
        ...(anchor && {
          marginTop: '2px',
        }),
      },
      ...(anchor && {
        anchorEl: anchor,
      }),
    };
  };

  render() {
    return (
      <AutoComplete
        fullWidth
        hintText={this.props.hintText || ''}
        // @ts-ignore
        anchorOrigin={this.props.anchorOrigin}
        animated={this.props.animated || true}
        // @ts-ignore
        animation={this.props.animation}
        errorStyle={this.props.errorStyle}
        errorText={this.props.errorText}
        disabled={this.props.disabled}
        floatingLabelStyle={this.props.floatingLabelStyle}
        floatingLabelFixed={this.props.floatingLabelFixed}
        underlineStyle={this.props.underlineStyle}
        listStyle={styles.listStyle}
        maxSearchResults={this.props.maxSearchResults}
        menuCloseDelay={this.props.menuCloseDelay}
        onClose={this.props.onClose}
        open={this.props.open || false}
        // @ts-ignore
        targetOrigin={this.props.targetOrigin}
        textFieldStyle={styles.textFieldStyle}
        menuStyle={this.props.menuStyle}
        underlineShow={false}
        // @ts-ignore
        searchText={this.state.searchText}
        onUpdateInput={this.updateInput}
        onChange={this.updateInput}
        filter={AutoComplete.noFilter}
        onNewRequest={this.onNewRequest}
        dataSource={this.getMenuItems()}
        popoverProps={this.getPopoverProps()}
      />
    );
  }
}

export default GooglePlaceAutoComplete;
