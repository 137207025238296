import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';

import Checkbox from '@atom/components/common/Checkbox';
import UserGroupIcon from '@atom/components/common/UserGroupIcon';
import { Icon, IconButton, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { UserGroupTreeType } from '@atom/types/userGroups';

import './manageUserGroupModal.css';

const PADDING_OFFSET = 2;

const styles = {
  arrowStyle: {
    fontSize: '24px',
    margin: 0,
    color: colors.neutral.gray,
  },
  indentation: {
    paddingLeft: '3rem',
  },
};

export interface Props {
  userGroup: UserGroupTreeType;
  indentation: number;
  openRow: Set<string>;
  selectedGroups: Set<string>;
  disabledGroupIds: Set<string>;
  toggleOpen: (id: string) => void;
  toggleSelect: (id: string) => void;
}

const ManageUserGroupsRow = ({
  userGroup,
  indentation,
  openRow,
  selectedGroups,
  toggleOpen,
  toggleSelect,
  disabledGroupIds,
}: Props) => {
  const expanded = openRow.has(userGroup.id);
  const arrowDirection = expanded ? 'arrow_drop_down' : 'arrow_right';
  const renderExpandedArrow = !R.isEmpty(userGroup.userGroups);

  const [overflowing, setOverflowing] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, []);

  const leftIcon = (
    <>
      {renderExpandedArrow && (
        <IconButton
          iconStyle={styles.arrowStyle}
          onClick={() => toggleOpen(userGroup.id)}
        >
          <Icon>{arrowDirection}</Icon>
        </IconButton>
      )}
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(2rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  const overflowStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: `calc(35rem - (2rem + ${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div styleName="row" style={containerStyle}>
        <div
          styleName="row-content sub-row"
          style={renderExpandedArrow ? {} : styles.indentation}
        >
          <div ref={container} styleName="left-content">
            {leftIcon}
            <Checkbox
              isChecked={selectedGroups.has(userGroup.id)}
              toggleCheck={() => toggleSelect(userGroup.id)}
              disabled={disabledGroupIds.has(userGroup.id)}
            />
            <div styleName="group-icon-container">
              <UserGroupIcon colorId={userGroup.colorId} />
            </div>
            <div styleName="name-container">
              <div styleName="title">
                {!overflowing ? (
                  userGroup.name
                ) : (
                  <Tooltip
                    lightTooltip
                    title={userGroup.name}
                    placement="right"
                  >
                    <p style={overflowStyle} styleName="title">
                      {userGroup.name}
                    </p>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {expanded ? (
        userGroup.userGroups.map((item: UserGroupTreeType) => (
          <ManageUserGroupsRow
            key={item.id}
            userGroup={item}
            indentation={indentation + 1}
            openRow={openRow}
            selectedGroups={selectedGroups}
            toggleOpen={toggleOpen}
            toggleSelect={toggleSelect}
            disabledGroupIds={disabledGroupIds}
          />
        ))
      ) : (
        <div />
      )}
    </>
  );
};

export default ManageUserGroupsRow;
