import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import WorkOrdersContext from '@atom/components/workOrders/WorkOrdersContext';
import { GET_WORK_ORDER } from '@atom/graph/work';
import { usePreviousValue } from '@atom/hooks/usePreviousValue';
import { Progress } from '@atom/mui';
import { TaskTemplate } from '@atom/types/taskTemplate';
import {
  WorkOrderAssetTreeType,
  WorkOrderDetailType,
  WorkOrderType,
  WorkValidations,
} from '@atom/types/work';
import api from '@atom/utilities/api';
import {
  TASK_TEMPLATES_ENDPOINT,
  WORK_ORDERS_ENDPOINT,
} from '@atom/utilities/endpoints';

import InfoView from './infoView/InfoView';
import MainView from './MainView';
import WorkOrderPreviewContext, {
  ExpandedInfo,
  reducer,
  View,
  WorkOrderActionTypes,
} from './WorkOrderPreviewContext';

import './workOrderPreview.css';

const WorkOrderPreview = () => {
  const { input } = useContext(WorkOrdersContext);

  const [workOrderDetail, dispatch] = useReducer(
    reducer,
    {} as WorkOrderDetailType,
  );
  const [activeView, setActiveView] = useState<View>(View.MAIN);
  const [expandedInfo, setExpandedInfo] = useState<Set<ExpandedInfo>>(
    new Set([
      ExpandedInfo.LOCATION,
      ExpandedInfo.SUMMARY,
      ExpandedInfo.CUSTOM,
      ExpandedInfo.DETAIL,
    ]),
  );
  const [collapsedTasks, setCollapsedTasks] = useState<Set<string>>(
    new Set([]),
  );
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
  const [loadingTaskTemplates, setLoadingTaskTemplates] = useState<boolean>(
    true,
  );
  const [activeWorkOrderAsset, setActiveWorkOrderAsset] = useState<string>('');
  const [workOrderAsset, setWorkOrderAsset] = useState<WorkOrderAssetTreeType>(
    // @ts-ignore
    {},
  );
  const previousWorkOrderAsset = usePreviousValue(workOrderAsset);
  const [workOrderAssetLoading, setWorkOrderAssetLoading] = useState<boolean>(
    false,
  );
  const [
    refetchWorkOrderAssetTreeLoading,
    setRefetchWorkOrderAssetTreeLoading,
  ] = useState<boolean>(false);
  const [workValidations, setWorkValidations] = useState<WorkValidations>({});

  const onCompleted = data => {
    const mappedWorkOrder = {
      ...data?.workOrder,
      type: R.pathOr(WorkOrderType.DEFAULT, ['workOrder', 'type'], data),
    };

    dispatch({
      type: WorkOrderActionTypes.SET_WORK_ORDER,
      data: mappedWorkOrder,
    });
  };

  const { loading, error, refetch } = useQuery(GET_WORK_ORDER, {
    variables: {
      id: input.workOrderId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted,
  });

  const resetState = () => {
    setActiveView(View.MAIN);
    setExpandedInfo(
      new Set([
        ExpandedInfo.LOCATION,
        ExpandedInfo.SUMMARY,
        ExpandedInfo.CUSTOM,
        ExpandedInfo.DETAIL,
      ]),
    );
    setCollapsedTasks(new Set([]));
    setTaskTemplates([]);
    setLoadingTaskTemplates(true);
    setActiveWorkOrderAsset('');
    // @ts-ignore
    setWorkOrderAsset({});
    setWorkOrderAssetLoading(false);
    setRefetchWorkOrderAssetTreeLoading(false);
    setWorkValidations({});
  };

  useEffect(() => {
    resetState();
    dispatch({
      type: WorkOrderActionTypes.SET_WORK_ORDER,
      // @ts-ignore
      data: {},
    });
  }, [input.workOrderId]);

  const getTaskTemplates = async (): Promise<TaskTemplate[]> => {
    const { data } = await api.get(TASK_TEMPLATES_ENDPOINT, {
      workOrderId: workOrderDetail.id,
    });

    setTaskTemplates(data);
    setLoadingTaskTemplates(false);
    return data;
  };

  useEffect(() => {
    if (workOrderDetail.id) {
      getTaskTemplates();
    }
  }, [workOrderDetail.id]);

  const refetchWorkOrderAssetTree = async () => {
    setRefetchWorkOrderAssetTreeLoading(true);
    const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${activeWorkOrderAsset}/tree`;
    const { data } = await api.get(endpoint);

    setRefetchWorkOrderAssetTreeLoading(false);
    return setWorkOrderAsset(data);
  };

  useEffect(() => {
    setWorkOrderAssetLoading(true);

    const getWorkOrderAssetTree = async () => {
      if (R.isEmpty(activeWorkOrderAsset)) {
        if (!R.isEmpty(previousWorkOrderAsset)) {
          refetch();
        }

        // @ts-ignore
        return setWorkOrderAsset({});
      }

      const endpoint = `${WORK_ORDERS_ENDPOINT}/${workOrderDetail.id}/assets/${activeWorkOrderAsset}/tree`;
      const { data } = await api.get(endpoint);

      return setWorkOrderAsset(data);
    };

    getWorkOrderAssetTree();
    setWorkOrderAssetLoading(false);
  }, [activeWorkOrderAsset, workOrderDetail.id]);

  const workOrderPreviewLoading = error || !workOrderDetail.id;

  const getContent = () => {
    const views = {
      [View.MAIN]: <MainView />,
      [View.INFO]: <InfoView />,
    };

    return views[activeView];
  };

  return (
    <WorkOrderPreviewContext.Provider
      value={{
        refetch,
        refetchWorkOrderAssetTree,
        refetchWorkOrderAssetTreeLoading,
        workOrderDetail,
        dispatch,
        loading,
        collapsedTasks,
        setCollapsedTasks,
        workOrderAssetLoading,
        workOrderAsset,
        setActiveWorkOrderAsset,
        activeView,
        setActiveView,
        expandedInfo,
        setExpandedInfo,
        taskTemplates,
        setTaskTemplates,
        loadingTaskTemplates,
        refetchTaskTemplates: getTaskTemplates,
        workValidations,
        setWorkValidations,
      }}
    >
      <div styleName="tray-container">
        {workOrderPreviewLoading ? (
          <div styleName="loading-container">
            <Progress />
          </div>
        ) : (
          getContent()
        )}
      </div>
    </WorkOrderPreviewContext.Provider>
  );
};

export default WorkOrderPreview;
