import React, { useEffect, useState } from 'react';

import { Button, Modal } from '@atom/mui';
import { MapAsset } from '@atom/types/map';
import { InvalidTaskFields, WorkOrderDetailType } from '@atom/types/work';
import { WorkTemplate as WorkTemplateType } from '@atom/types/workTemplate';
import { pluralize } from '@atom/utilities/stringUtilities';

import FormView from './formView/FormView';
// validateAll is not used from the custom hook, because
// this modal is outside BulkContext.Provider, the code
// that calls validateAll lives on the footer.
import { validateAll } from './hooks/bulkWorkOrderHook';
import BulkContext, {
  coreTabs,
  initialState,
  Tab,
  View,
  WorkTemplate,
} from './BulkContext';
import CreateModal from './CreateModal';
import WorkTypeSelectionView from './WorkTypeSelectionView';

import './bulkCreateModal.css';

const styles = {
  modal: {
    padding: '0',
  },
};

export interface Props {
  assets: MapAsset[];
  open: boolean;
  onClose: () => void;
}

const BulkCreateModal = ({ assets, open, onClose }: Props) => {
  const [name, setName] = useState<string>('');
  const [view, setView] = useState<View>(View.WORK_TYPE);
  const [workTemplate, setWorkTemplate] = useState<WorkTemplate>(null);
  const [invalidFields, setInvalidFields] = useState<InvalidTaskFields>({});
  const [invalidTabs, setInvalidTabs] = useState<Set<string>>(new Set());
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [workOrder, setWorkOrder] = useState<WorkOrderDetailType>(
    initialState.workOrder,
  );
  const [workTemplateType, setWorkTemplateType] = useState<WorkTemplateType>(
    null,
  );
  const [tab, setTab] = useState<Tab>(coreTabs[0]);

  const isInvalid = invalidTabs.size !== 0;

  const resetModal = () => {
    setName('');
    setView(View.WORK_TYPE);
    setWorkTemplate(null);
    setInvalidFields({});
    setInvalidTabs(new Set());
    setCreateModalOpen(false);
    setWorkOrder(initialState.workOrder);
    setWorkTemplateType(null);
    setTab(coreTabs[0]);
  };

  useEffect(() => {
    // When cancelling/opening the modal, reset the modal's state:
    if (open) {
      resetModal();
    }
  }, [open]);

  const onCreate = () => {
    // Validate all steps and only open/enable the creation
    // modal if all is valid, else switch to the first step
    // with errors:
    validateAll(
      workOrder,
      invalidTabs,
      invalidFields,
      setInvalidTabs,
      setInvalidFields,
      setTab,
    ).then(() => {
      setCreateModalOpen(true);
    });
  };

  const closeModal = () => setCreateModalOpen(false);

  const closeAll = () => {
    setCreateModalOpen(false);
    onClose();
  };

  const getFooter = () => {
    if (view === View.WORK_TYPE) {
      return (
        <div styleName="footer">
          <Button onClick={onClose}>CANCEL</Button>
        </div>
      );
    }
    return (
      <div styleName="footer footer-full">
        <Button onClick={resetModal}>BACK</Button>
        <div styleName="footer-group">
          {isInvalid && (
            <div styleName="footer-error">
              Please fill out all required fields.
            </div>
          )}
          <Button onClick={onClose}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={onCreate}>
            CREATE
          </Button>
        </div>
      </div>
    );
  };

  const contextValue = {
    tab,
    setTab,
    invalidTabs,
    setInvalidTabs,
    invalidFields,
    setInvalidFields,
    assets,
    name,
    workTemplate,
    setName,
    setView,
    setWorkTemplate,
    setWorkOrder,
    workOrder,
    workTemplateType,
    setWorkTemplateType,
  };

  // The FORM view must be wider:
  const modalWidth = view === View.WORK_TYPE ? 'sm' : 'xl';

  // Only display the Modal's content if we are on the WORK_TYPE selection view
  // or if we are on the FORM one after a work template was selected.
  // (i.e. don't display it on the FORM while work template is still loading, to
  // allow for the Progress spinner to show).
  const displayContent =
    view === View.WORK_TYPE || (view === View.FORM && workTemplate);

  return (
    <>
      <Modal
        contentStyle={styles.modal}
        disableHeader
        disableFooter={!displayContent}
        footer={getFooter()}
        open={open}
        title=""
        width={modalWidth}
      >
        {displayContent && (
          <div styleName="title">
            <div styleName="title-label">
              Create {assets.length} {pluralize(assets.length, 'Work Item')} in
              Bulk
            </div>
            {view === View.FORM && (
              <div styleName="title-subtext">
                Take bulk actions on the{' '}
                {assets.length === 1
                  ? 'work item'
                  : `${assets.length} work items`}{' '}
                you're about to create. Note that only certain fields on the
                work template will be visible for bulk entry, but you can always
                add any additional information later on the work or task detail
                page.
              </div>
            )}
          </div>
        )}
        <BulkContext.Provider value={contextValue}>
          {view === View.WORK_TYPE && <WorkTypeSelectionView />}
          {view === View.FORM && <FormView />}
        </BulkContext.Provider>
      </Modal>
      <CreateModal
        assets={assets}
        open={createModalOpen}
        onClose={closeModal}
        onFinish={closeAll}
        workOrder={workOrder}
      />
    </>
  );
};

export default BulkCreateModal;
