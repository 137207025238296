import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from '@atom/actions/workOrderActions';
import ListViewTable from '@atom/components/common/listViewTable/ListViewTable';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import { Progress } from '@atom/mui';
import { WorkOrderActions } from '@atom/types/actions';
import { ReduxStore } from '@atom/types/store';
import history from '@atom/utilities/history';
import { incrementPage } from '@atom/utilities/listViewTableUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { rollUpTaskUsers } from '@atom/utilities/userUtilities';

import AssetWorkOrdersFilters from './AssetWorkOrdersFilters';

// @ts-ignore
import bodyPaneStyles from '../../../../styles/body-pane.css';
// @ts-ignore
import tableStyles from '../../../../styles/table.css';

/* Note that this is to satisfy the webpack build step which would
   automatically de-reference/scrub the import of styles as they arent
   used anywhere in the code.  Passing them as magic strings to styleName
   is irrelevant

   For additional reading:
   https://github.com/gajus/babel-plugin-react-css-modules/issues/130
*/

// @ts-ignore
let styleReference = bodyPaneStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
styleReference = tableStyles;

const styles = {
  tableStyle: {
    width: '100%',
    marginTop: 0,
    marginBottom: '3.125rem',
    height: 'calc(100vh - 320px)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: '3.125rem',
    paddingLeft: '1.25rem',
  },
};

const displayHeaders = [
  'Work ID',
  'Status',
  'Due Date',
  'Completed Date',
  'Assigned To',
];
const fields = ['name', 'statusId', 'dueDate', 'completionDate', 'users'];

const initialState = {
  page: 1,
  limit: 25,
  loading: true,
  filters: {},
  sortBy: 'name',
  isAscending: true,
};

interface PassedProps {
  assetId: string;
}

interface ReduxStateProps {
  workOrders: any[];
  workOrdersTotal: number;
}

interface ReduxDispatchProps {
  workOrderActions: WorkOrderActions;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  page: number;
  limit: number;
  loading: boolean;
  filters: any;
  sortBy: any;
  isAscending: any;
}

// TODO: [ATOM-1889]: Implement Sort Functionality
class AssetWorkOrders extends React.Component<Props, State> {
  state = initialState;

  componentDidMount() {
    this.resetToInitialState();
  }

  resetToInitialState = async () => {
    this.setState({ loading: true });

    const { workOrderActions, assetId } = this.props;
    const { page, limit, filters, sortBy, isAscending } = initialState;
    const filtersWithAssetId = { ...filters, assetId };

    await workOrderActions.getWorkOrders(
      page,
      limit,
      filtersWithAssetId,
      sortBy,
      isAscending,
    );

    this.setState({
      ...initialState,
      loading: false,
    });
  };

  incrementPage = async increment => {
    const { workOrderActions, assetId } = this.props;
    const { page, limit, filters, sortBy, isAscending } = this.state;
    const filtersWithAssetId = { ...filters, assetId };

    this.setState(incrementPage(increment));
    return await workOrderActions.getWorkOrders(
      page + increment,
      limit,
      filtersWithAssetId,
      sortBy,
      isAscending,
    );
  };

  updateLimit = async limit => {
    const { workOrderActions, assetId } = this.props;
    const { filters, sortBy, isAscending } = this.state;
    const filtersWithAssetId = { ...filters, assetId };

    const page = 1;
    this.setState({ limit, page });

    return await workOrderActions.getWorkOrders(
      page,
      limit,
      filtersWithAssetId,
      sortBy,
      isAscending,
    );
  };

  applyFilters = async filters => {
    const { workOrderActions, assetId } = this.props;
    const { limit, sortBy, isAscending } = this.state;
    const { page } = initialState;
    this.setState({ loading: true });
    const filtersWithAssetId = { ...filters, assetId };

    await workOrderActions.getWorkOrders(
      page,
      limit,
      filtersWithAssetId,
      sortBy,
      isAscending,
    );

    this.setState({
      loading: false,
      page,
      filters,
    });
  };

  navigateToWorkOrderDetail = id => {
    const { workOrderActions } = this.props;
    workOrderActions.retrieveWorkOrder(id);
    history.push(`/workOrders/${id}`);
  };

  buildCell = (row, field, index) => {
    const fieldTemplates = {
      name: (
        <td
          key={index}
          styleName="tableStyles.clickable"
          onClick={() => this.navigateToWorkOrderDetail(row.id)}
        >
          {row.name}
        </td>
      ),
      statusId: (
        <td key={index} styleName="tableStyles.status-pill">
          <WorkOrderStatusPill
            statusId={row.statusId}
            className="detail-pane"
          />
        </td>
      ),
      dueDate: <td key={index}>{setDisplayDate(row.dueDate)}</td>,
      completionDate: <td key={index}>{setDisplayDate(row.completionDate)}</td>,
      users: (
        <td key={index} styleName="tableStyles.assigned-users">
          <WorkUsers users={rollUpTaskUsers(row.tasks)} />
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  render() {
    const { workOrders, workOrdersTotal } = this.props;
    const { loading, page, limit } = this.state;

    const mainPaneContent = loading ? (
      <Progress style={{ height: '100%' }} />
    ) : (
      <ListViewTable
        style={styles.tableStyle}
        displayHeaders={displayHeaders}
        dataRows={workOrders}
        fields={fields}
        // @ts-ignore
        buildCell={this.buildCell}
        // @ts-ignore
        totalRows={workOrdersTotal}
        // @ts-ignore
        incrementPage={this.incrementPage}
        // @ts-ignore
        updateLimit={this.updateLimit}
        page={page}
        limit={limit}
      />
    );

    return (
      <div styleName="bodyPaneStyles.body-container">
        <div styleName="bodyPaneStyles.left-body-pane-fixed">
          <AssetWorkOrdersFilters
            applyFilters={this.applyFilters}
            resetWorkOrdersState={this.resetToInitialState}
          />
        </div>
        <div styleName="bodyPaneStyles.right-body-pane-fill">
          <div style={styles.title}>{`${workOrdersTotal} work`}</div>
          {mainPaneContent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  workOrders: state.workOrders,
  workOrdersTotal: state.workOrdersTotal,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  workOrderActions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetWorkOrders);
