import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import WorkUsers from '@atom/components/common/workUsers/WorkUsers';
import { ListTable, Progress } from '@atom/mui';
import { WorkOrder, WorkOrderUserGroup } from '@atom/types/work';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { rollUpTaskUsers } from '@atom/utilities/userUtilities';

import { RESULTS_PER_PAGE_OPTIONS } from './AssetWorkOrdersContainer';

import './assetWorkOrdersTable.css';

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

const headerSpacerAndTableFooterOffset = '6.5rem';

const styles = {
  tableContainer: {
    height: `calc(100% - ${headerSpacerAndTableFooterOffset})`,
  },
  progress: {
    height: '100%',
  },
  link: {
    fontWeight: '500',
  },
};

interface Props {
  workOrders: WorkOrder[] | undefined;
  loadingWorkOrders: boolean;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  sortDirection: 'asc' | 'desc';
  setSortDirection: Dispatch<SetStateAction<'asc' | 'desc'>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  totalCount: number;
}

const AssetWorkOrdersTable = ({
  workOrders,
  loadingWorkOrders,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
}: Props) => {
  const handleSortChange = (sortField: string, direction: 'asc' | 'desc') => {
    setSortBy(sortField);
    setSortDirection(direction);
  };

  if (loadingWorkOrders) {
    return <Progress style={styles.progress} />;
  }

  return (
    <>
      <div styleName="table-header-spacer">{`${totalCount} Work`}</div>
      <ListTable TableContainerProps={{ style: styles.tableContainer }}>
        <TableHead>
          <TableRow header>
            <TableCell
              variant="head"
              sortDirection={sortBy === 'name' ? sortDirection : false}
              onSortChange={direction => handleSortChange('name', direction)}
            >
              Work ID
            </TableCell>
            <TableCell variant="head">Work Template</TableCell>
            <TableCell
              variant="head"
              sortDirection={sortBy === 'statusId' ? sortDirection : false}
              onSortChange={direction =>
                handleSortChange('statusId', direction)
              }
            >
              Status
            </TableCell>
            <TableCell variant="head">Assigned To</TableCell>
            <TableCell
              variant="head"
              sortDirection={sortBy === 'dueDate' ? sortDirection : false}
              onSortChange={direction => handleSortChange('dueDate', direction)}
            >
              Due Date
            </TableCell>
            <TableCell
              variant="head"
              sortDirection={
                sortBy === 'completionDate' ? sortDirection : false
              }
              onSortChange={direction =>
                handleSortChange('completionDate', direction)
              }
            >
              Completed Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrders?.map(workOrder => (
            <TableRow key={workOrder.id}>
              <TableCell>
                <Link style={styles.link} to={`/workOrders/${workOrder.id}`}>
                  {workOrder.name}
                </Link>
              </TableCell>
              <TableCell>{workOrder.workTemplate?.name || '-'}</TableCell>
              <TableCell>
                <WorkOrderStatusPill
                  className="table"
                  statusId={workOrder.statusId}
                />
              </TableCell>
              <TableCell>
                <WorkUsers
                  users={rollUpTaskUsers(workOrder.tasks as any)}
                  userGroups={workOrder.tasks.reduce(
                    (acc: WorkOrderUserGroup[], task) => [
                      ...acc,
                      ...task.userGroups,
                    ],
                    [],
                  )}
                />
              </TableCell>
              <TableCell>{setDisplayDate(workOrder.dueDate)}</TableCell>
              <TableCell>{setDisplayDate(workOrder.completionDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={RESULTS_PER_PAGE_OPTIONS}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={page => setCurrentPage(page)}
              onRowsPerPageChange={event => {
                setRowsPerPage(+event.target.value);
                setCurrentPage(1);
              }}
            />
          </TableRow>
        </TableFooter>
      </ListTable>
    </>
  );
};

export default AssetWorkOrdersTable;
