import React from 'react';

import { WorkOrderAssetTreeType, WorkOrderDetailType } from '@atom/types/work';

interface Context {
  workOrderDetail: WorkOrderDetailType;
  workOrderAsset: WorkOrderAssetTreeType;
  setActiveWorkOrderAsset: Function;
  refetchWorkOrderAssetTreeLoading: boolean;
  refetchWorkOrderAssetTree: () => void;
  activeElementId: string;
  setActiveElementId: React.Dispatch<React.SetStateAction<string>>;
  refetch: () => void;
  previewView: boolean;
  hasUnsavedChanges: boolean;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

export const initialState: Context = {
  // @ts-ignore
  workOrderDetail: {},
  // @ts-ignore
  workOrderAsset: {},
  setActiveWorkOrderAsset: () => {},
  refetchWorkOrderAssetTreeLoading: false,
  refetchWorkOrderAssetTree: () => {},
  activeElementId: null,
  setActiveElementId: () => {},
  refetch: () => {},
  previewView: false,
  hasUnsavedChanges: false,
  setUnsavedChanges: () => {},
};

const WorkOrderAssetViewContext = React.createContext<Context>(initialState);

export default WorkOrderAssetViewContext;
