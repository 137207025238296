import React, { useEffect } from 'react';
import * as R from 'ramda';

import { useInventoryAsset } from '@atom/hooks/useInventoryAsset';
import { Progress } from '@atom/mui';
import {
  formTemplateContainsModule,
  isCurrentPageStandaloneModule,
} from '@atom/selectors/formModuleSelectors';
import {
  CiModuleInspectionUpdate,
  FormModuleKeys,
  PciModuleDataUpdate,
} from '@atom/types/form';
import { InventorySchemaItem } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';

import FieldBlock from './FieldBlock';

import './formInstance.css';

interface PassedProps {
  form: any;
  media: MediaItem[];
  preview: boolean;
  rootSchema: InventorySchemaItem;
  selectedPage: number;
  loading: boolean;
  uploadFiles: (
    files?: any[],
    subjectId?: string,
    subjectType?: string,
    inspectionUpdate?: CiModuleInspectionUpdate,
  ) => void;
  progressiveUpdateFormInstanceField: (fieldId: string, body: Object) => void;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  progressiveUpdateFormInstanceFhwa: (
    fieldId: string,
    assetId: string,
    body: Object,
    elementPath: any[],
  ) => void;
  progressiveUpdateFormInstanceCi: (update: CiModuleInspectionUpdate) => void;
  progressiveUpdateFormInstancePci: (update: PciModuleDataUpdate) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  savingFormInstance: boolean;
}

type Props = PassedProps;

const FormInstanceCanvas = ({
  form,
  media,
  selectedPage,
  loading,
  progressiveUpdateFormInstanceField,
  progressiveUpdateFormInstanceAttribute,
  progressiveUpdateFormInstanceFhwa,
  progressiveUpdateFormInstanceCi,
  progressiveUpdateFormInstancePci,
  removeFile,
  rootSchema,
  uploadFiles,
  preview,
  renameMedia,
  savingFormInstance,
}: Props) => {
  const inventoryAssetId = R.pathOr('', ['inventoryAssetId'], form);
  const isEditable = R.pathOr(false, ['isEditable'], form);

  const page = form.pages[selectedPage];
  const formInstanceId = form.id;
  const isModulePage = isCurrentPageStandaloneModule(form, selectedPage);
  const canvasStyle = isModulePage ? 'module' : '';

  const [
    asset,
    { refetch: fetchAsset, loading: loadingAsset },
  ] = useInventoryAsset(inventoryAssetId, { lazy: true });

  useEffect(() => {
    if (
      inventoryAssetId &&
      formTemplateContainsModule(FormModuleKeys.PCI, form)
    ) {
      fetchAsset();
    }
  }, [inventoryAssetId]);

  const content =
    loading || loadingAsset || R.isNil(page) ? (
      <div styleName="spinner-container">
        <Progress style={{ height: '100%' }} />
      </div>
    ) : (
      page.order.map((fieldId: string) => {
        const field = page.fields[fieldId];
        return (
          <FieldBlock
            isEditable={isEditable}
            preview={preview}
            uploadFiles={uploadFiles}
            rootSchema={rootSchema}
            media={media}
            inventoryAsset={asset}
            inventoryAssetId={inventoryAssetId}
            field={field}
            pageName={page.name}
            key={fieldId}
            id={fieldId}
            formInstanceId={formInstanceId}
            progressiveUpdateFormInstanceField={
              progressiveUpdateFormInstanceField
            }
            progressiveUpdateFormInstanceAttribute={
              progressiveUpdateFormInstanceAttribute
            }
            progressiveUpdateFormInstanceFhwa={
              progressiveUpdateFormInstanceFhwa
            }
            progressiveUpdateFormInstanceCi={progressiveUpdateFormInstanceCi}
            progressiveUpdateFormInstancePci={progressiveUpdateFormInstancePci}
            removeFile={removeFile}
            renameMedia={renameMedia}
            savingFormInstance={savingFormInstance}
          />
        );
      })
    );

  return <div styleName={`form-preview-section ${canvasStyle}`}>{content}</div>;
};

export default FormInstanceCanvas;
