import React, { useState } from 'react';
import { WorkOrderDetailType } from 'client/types/work';

import { WorkOrderAssetTreeType } from '@atom/types/work';

import WorkOrderAssetTree from './workOrderAssetTree/WorkOrderAssetTree';
import WorkOrderElementView from './workOrderElementView/WorkOrderElementView';
import WorkOrderAssetHeader from './WorkOrderAssetHeader';
import WorkOrderAssetViewContext from './WorkOrderAssetViewContext';

import './workOrderAssetView.css';

interface Props {
  workOrderDetail: WorkOrderDetailType;
  workOrderAsset: WorkOrderAssetTreeType;
  setActiveWorkOrderAsset: Function;
  refetchWorkOrderAssetTreeLoading: boolean;
  refetchWorkOrderAssetTree: () => void;
  refetch: () => void;
  previewView?: boolean;
}

const WorkOrderAssetView = ({
  workOrderDetail,
  workOrderAsset,
  setActiveWorkOrderAsset,
  refetchWorkOrderAssetTreeLoading,
  refetchWorkOrderAssetTree,
  refetch,
  previewView = false,
}: Props) => {
  const [activeElementId, setActiveElementId] = useState<string>(
    workOrderAsset.id,
  );
  const [hasUnsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  return (
    <WorkOrderAssetViewContext.Provider
      value={{
        workOrderDetail,
        workOrderAsset,
        setActiveWorkOrderAsset,
        refetchWorkOrderAssetTreeLoading,
        refetchWorkOrderAssetTree,
        refetch,
        activeElementId,
        setActiveElementId,
        previewView,
        hasUnsavedChanges,
        setUnsavedChanges,
      }}
    >
      <div styleName="work-order-asset-container">
        <WorkOrderAssetHeader />
        <div styleName="work-order-content">
          <div styleName="pane left">
            <WorkOrderAssetTree />
          </div>
          <div styleName="pane right">
            <WorkOrderElementView />
          </div>
        </div>
      </div>
    </WorkOrderAssetViewContext.Provider>
  );
};

export default WorkOrderAssetView;
