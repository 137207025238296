import React from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import { setWorkTimeDisplay } from '@atom/utilities/timeUtilities';

import './workTemplateTaskDetail.css';

const TaskWorkTime = () => {
  const tooltipContent = (
    <div styleName="tooltip-content">
      <div>Total labor time for this task.</div>
    </div>
  );

  return (
    <div styleName="time-row-item">
      <div styleName="label-container">
        <div styleName="label">Work Time</div>
        <Tooltip content={tooltipContent} />
      </div>
      <div styleName="date-display">{setWorkTimeDisplay(0)}</div>
    </div>
  );
};

export default TaskWorkTime;
