import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  GoogleMap,
  GoogleMapProps,
  TrafficLayer,
} from '@react-google-maps/api';

// @ts-ignore
import draggableCursorIcon from '@atom/components/common/svgIcons/draggableCursor.svg';
// @ts-ignore
import draggingCursorIcon from '@atom/components/common/svgIcons/draggingCursor.svg';
import mapConstants from '@atom/constants/mapConstants';
import { getLoggedInUserMapLocationSelector } from '@atom/selectors/mapSelectors';
import {
  LocationLinkOption,
  LocationUpdatePayload,
  MapEditMetadata,
  MapParams,
} from '@atom/types/map';
import { DEFAULT_MAP_ZOOM } from '@atom/utilities/mapUtilities';

import { useGoogleMapsStateValue } from './hooks/googleMapsStateHook';
import MapTools from './mapTools/MapTools';

import './map.css';

interface Props extends GoogleMapProps {
  children: React.ReactNode;
  onLoad: (MapComponent) => void;
  onIdle: () => void;
  mapSearchBox?: boolean;
  layerControls?: boolean;
  geographicLayer?: boolean;
  mapStyleControl?: boolean;
  drawingControls?: boolean;
  initialOpenEditList?: boolean;
  locationLinkControls?: boolean;
  editControls?: boolean;
  mapEditMetadata?: MapEditMetadata[];
  zoom?: number;
  center?: google.maps.LatLngLiteral;
  loading?: boolean;
  onSave?: (payload: LocationUpdatePayload) => void;
  locationEditZoom?: number;
  locationLinkOptions?: LocationLinkOption[];
  locationLinkControlsDisabled?: boolean;
  // By default the map interacts with center, zoom, id, searchPoint, and searchTerm
  // via URL Params (use-query-params library)
  // If you would like to instead store these in component state, you must pass in mapParams
  // and updateMapParams props. This will also need to be passed into <MapMarkers />
  mapParams?: MapParams;
  updateMapParams?: (mapParams: MapParams) => void;
  onCenterMapClick?: () => void;
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Map = ({
  children,
  onLoad,
  onIdle,
  layerControls,
  mapSearchBox,
  geographicLayer,
  mapStyleControl,
  drawingControls,
  editControls,
  mapEditMetadata,
  zoom,
  center,
  loading = false,
  onSave,
  initialOpenEditList = true,
  locationEditZoom,
  locationLinkControls,
  locationLinkOptions,
  locationLinkControlsDisabled,
  mapParams,
  updateMapParams,
  onCenterMapClick,
  ...rest
}: Props) => {
  const {
    mapStyle,
    poi,
    mapTypeId,
    traffic,
    grab,
    shape,
  } = useGoogleMapsStateValue();

  const defaultCenter = useSelector(getLoggedInUserMapLocationSelector);

  const [streetViewVisible, setStreetViewVisible] = useState<boolean>(false);
  const [streetView, setStreetView] = useState<google.maps.StreetViewPanorama>(
    null,
  );
  const [editItem, setEditItem] = useState<MapEditMetadata>(null);

  const { mapStyles, draggableCursor, draggingCursor } = mapConstants;
  const styles = mapStyles[mapStyle];

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleLoad = useCallback((map: google.maps.Map) => {
    // eslint-disable-next-line no-undef
    const sv = new google.maps.StreetViewPanorama(map.getDiv(), {
      enableCloseButton: true,
      addressControlOptions: {
        position: 3,
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 3,
      },
      visible: false,
    });

    sv.addListener('visible_changed', () => {
      setStreetViewVisible(sv.getVisible());
    });

    setStreetView(sv);

    onLoad(map);
  }, []);

  const defaultOptions: google.maps.MapOptions = {
    styles: [
      ...styles,
      ...(!poi ? [mapConstants.disablePointsOfInterest] : []),
    ],
    mapTypeId,
    streetView,
    mapTypeControl: false,
    zoomControl: true,
    streetViewControl: true,
    scaleControl: true,
    draggableCursor: grab
      ? `url(${draggableCursorIcon}) 40 40, auto`
      : draggableCursor,
    draggingCursor: grab
      ? `url(${draggingCursorIcon}) 40 40, auto`
      : draggingCursor,
    gestureHandling: 'greedy',
    fullscreenControl: false,
    clickableIcons: !grab,
  };

  // disable custom controls in street view
  const props = streetViewVisible
    ? {}
    : {
        mapSearchBox,
        mapStyleControl,
        layerControls,
        drawingControls,
        editControls,
        mapEditMetadata,
        editItem,
        setEditItem,
        onSave,
        initialOpenEditList,
        locationEditZoom,
        locationLinkControls,
        locationLinkOptions,
        locationLinkControlsDisabled,
        mapParams,
        updateMapParams,
        onCenterMapClick,
      };

  // Removes map markers when edit mode is engaged and a user has selected a new location
  // For editing LineStrings, the map marker is remove immediately on edit click
  const displayMapChildren =
    // @ts-ignore
    !editItem || (!shape && editItem?.location?.type !== 'LineString');

  return (
    <div styleName="map-wrapper">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center || defaultCenter}
        zoom={zoom || DEFAULT_MAP_ZOOM}
        onLoad={handleLoad}
        onIdle={onIdle}
        options={defaultOptions}
        {...rest}
      >
        {displayMapChildren && children}
        <MapTools loading={loading} {...props} />
        {traffic && <TrafficLayer />}
      </GoogleMap>
    </div>
  );
};

export default React.memo(Map);
