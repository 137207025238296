import React from 'react';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './workTemplateChildCreate.css';

const styles = {
  back: {
    marginRight: '1rem',
  },
};

enum View {
  MAIN = 'MAIN',
  WORK_TYPE_FOLDER = 'WORK_TYPE_FOLDER',
  INHERITANCE = 'INHERITANCE',
}

interface Props {
  view: View;
  setView: (view: View) => void;
}

const ChildCreateTitle = ({ view, setView }: Props) => {
  const tooltipContent = (
    <div styleName="tooltip">
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is disabled by default, the child work template must
          inherit content from that section as it appears on the parent, without
          permission to modify the inherited content.
        </div>
      </div>
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is enabled, the child work template has the option to
          select that section to inherit its content as it appears on the
          parent, without permission to modify the inherited content.
        </div>
      </div>
      <div styleName="tooltip-row">
        <div>&bull;</div>
        <div styleName="tooltip-content">
          When a section is enabled but not selected, the child work template
          will not inherit any content from the parent for that section.
        </div>
      </div>
      <div styleName="tooltip-row">
        In all scenarios, the child work template can add additional fields,
        files, or items to any of the sections.
      </div>
    </div>
  );

  const title =
    view === View.WORK_TYPE_FOLDER
      ? 'Choose Folder'
      : 'Create Child Work Template';

  return (
    <div styleName="header-container">
      <div styleName="title">
        {view === View.WORK_TYPE_FOLDER && (
          <IconButton
            style={styles.back}
            onClick={() => setView(View.MAIN)}
            size="small"
          >
            <Icon>arrow_back</Icon>
          </IconButton>
        )}
        {title}
      </div>
      {view === View.INHERITANCE && (
        <Tooltip
          content={tooltipContent}
          iconSize="1.125rem"
          iconColor={colors.neutral.gray}
          position="bottom-left"
        />
      )}
    </div>
  );
};

export default ChildCreateTitle;
