import * as React from 'react';
import * as R from 'ramda';

import {
  AttributesType,
  HydratedAttributeGroupsItem,
} from '@atom/types/inventory';

import InstanceAttributeField from './InstanceAttributeField';

import '../formInstance.css';

interface PassedProps {
  attributeGroup: HydratedAttributeGroupsItem;
  isEditable: boolean;
  assetId: string;
  fieldId: string;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  savingFormInstance: boolean;
}

type Props = PassedProps;

const InstanceAttributeGroup = ({
  attributeGroup,
  assetId,
  fieldId,
  isEditable,
  progressiveUpdateFormInstanceAttribute,
  savingFormInstance,
}: Props) => {
  const attributes = R.pathOr([], ['attributes'], attributeGroup);

  return (
    <div key={attributeGroup.name} styleName="attribute-group-block">
      <div styleName="attribute-group-name">{attributeGroup.name}</div>
      {attributes.map((attribute: AttributesType) => (
        <InstanceAttributeField
          isEditable={isEditable}
          key={attribute.id}
          attribute={attribute}
          attributeGroupName={attributeGroup.name}
          assetId={assetId}
          fieldId={fieldId}
          progressiveUpdateFormInstanceAttribute={
            progressiveUpdateFormInstanceAttribute
          }
          savingFormInstance={savingFormInstance}
        />
      ))}
    </div>
  );
};

export default InstanceAttributeGroup;
