import * as React from 'react';

import { Button, Checkbox, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { workOrderStatusTypes } from '@atom/utilities/workOrderStatusUtilities';

import '../../../../styles/left-pane.css';

const { MenuItem } = Select;

const filterStyle = {
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  floatingLabelStyle: {
    color: colors.neutral.gray,
  },
  selectFieldStyle: {
    input: {
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
  applyButtonStyle: {
    marginTop: '0.5rem',
  },
  resetButtonStyle: {
    marginTop: '0.5rem',
    marginLeft: '2rem',
  },
};

interface Props {
  applyFilters: (data: object) => void;
  resetWorkOrdersState: () => void;
}

interface State {
  filters: any;
}

class AssetWorkOrdersFilters extends React.Component<Props, State> {
  state = {
    filters: {
      statusIds: [],
    },
  };

  updateStatusFilter = event => {
    const { value } = event.target;
    const { filters } = this.state;
    filters.statusIds = value;
    this.setState({
      filters,
    });
  };

  resetFilters = () => {
    const { resetWorkOrdersState } = this.props;

    this.setState({
      filters: {
        statusIds: [],
      },
    });

    resetWorkOrdersState();
  };

  render() {
    const { filters } = this.state;
    const { applyFilters } = this.props;

    return (
      <div styleName="detail-left-pane">
        <div styleName="filters-header">
          <div styleName="filters-header-label">Filter</div>
          <Button
            style={filterStyle.resetButtonStyle}
            onClick={this.resetFilters}
          >
            Reset
          </Button>
          <Button
            color="primary"
            style={filterStyle.applyButtonStyle}
            onClick={() => applyFilters({ ...filters })}
          >
            Apply
          </Button>
        </div>
        <div styleName="select-filter-wrapper">
          <Select
            multiple
            fullWidth
            label="Work Status"
            onChange={this.updateStatusFilter}
            value={filters.statusIds}
            renderValue={(selected: string[]) =>
              workOrderStatusTypes
                .filter(item => selected.indexOf(item.value) !== -1)
                .map(item => item.label)
                .join(', ')
            }
          >
            {workOrderStatusTypes.map(status => {
              return (
                <MenuItem key={status.value} value={status.value}>
                  <Checkbox
                    checked={filters.statusIds.includes(status.value)}
                  />
                  {status.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
}

export default AssetWorkOrdersFilters;
