import React from 'react';

import { AttributesType, InventorySchemaItem } from './inventory';
import { MediaItem } from './media';
import { UserProfile } from './user';

export interface AncestorsItem {
  id: string;
  name: string;
}

export interface AttributeGroupItem {
  name: string;
  attributes: AttributesType[];
}

export interface AssetSchemaType {
  id: string;
  assetType: string;
  name: string;
  ancestors: AncestorsItem[];
  attributeGroups: AttributeGroupItem[];
}

export interface FormFieldType {
  id?: string;
  type: string;
  text?: string;
  title?: string;
  description?: string;
  required?: boolean;
  fileUpload?: boolean;
  unit?: string;
  enum?: string[];
  value?: string | string[] | number;
  url?: string;
  width?: number;
  assetSchemas?: AssetSchemaType[];
  conditionInspectionId?: string;
}

export interface FormFields {
  [key: string]: FormFieldType;
}

export interface FormFieldPageType {
  id: string;
  order: string[];
  name: string;
  fields: FormFields;
}

export interface FormTemplateType {
  id?: string;
  name?: string;
  instanceCount?: number;
  isPublished?: boolean;
  schemaId?: string;
  createdDate?: number;
  createdBy?: string;
  pages: FormFieldPageType[];
}

export interface FormTemplateInfo {
  id: string;
  name: string;
  createdDate: number;
  createdBy: UserProfile;
  instanceCount: number;
  isPublished: boolean;
  schemaId?: string;
  modules: FormModuleKeys[];
}

export interface FormInstanceAssetItem {
  id: string;
  assetType: string;
  name: string;
  ancestors: AncestorsItem[];
  attributeGroups: AttributeGroupItem[];
}

export interface FhwaModuleAttributeType {
  id: string;
  name: string;
  dataType: string;
  enumeration: string[];
  value: any;
}

export interface FhwaModuleAttributesType {
  environment: FhwaModuleAttributeType;
  totalQuantity: FhwaModuleAttributeType;
  remarks: FhwaModuleAttributeType;
  cs1: FhwaModuleAttributeType;
  cs2: FhwaModuleAttributeType;
  cs3: FhwaModuleAttributeType;
  cs4: FhwaModuleAttributeType;
  cs1Percent: FhwaModuleAttributeType;
  cs2Percent: FhwaModuleAttributeType;
  cs3Percent: FhwaModuleAttributeType;
  cs4Percent: FhwaModuleAttributeType;
}

export interface FhwaModuleElementType {
  name: string;
  markerId: number;
  schemaId: string;
  order: string[];
  attributes: FhwaModuleAttributesType;
  elements?: FhwaModuleElements;
}

export interface FhwaModuleElements {
  [key: string]: FhwaModuleElementType;
}

export interface CiModuleScore {
  id: string;
  name: string;
  value?: number;
}

export enum PciType {
  AC = 'AC',
  AC_OVER_PCC = 'AC over PCC',
  PCC_NBI = 'PCC NBI',
}

export interface PciModuleInspectionOptions {
  distress: PciModuleInspectionOption[];
  severity: PciModuleInspectionOption[];
}

export interface PciModuleInspectionOption {
  name: string;
  description: string;
  descriptionSubtexts?: string[];
  points: number;
}

export interface PciModuleDistress {
  id: string;
  distress?: string;
  severity?: string;
  quantity?: number;
  mediaIds?: string[];
  notes?: string;
}

export interface PciModuleData {
  type: PciType;
  name: string;
  date: number;
  notes: string;
  hasNoDistress: boolean;
  distress: PciModuleDistress[];
  options: PciModuleInspectionOptions;
  discrepancyWarning?: string;
}

export interface PciModuleDataUpdate {
  pageId?: string;
  fieldId: string;
  name?: string;
  date?: number;
  notes?: string;
  hasNoDistress?: boolean;
  distress?: PciModuleDistressUpdate[];
}

export interface PciModuleDistressUpdate {
  id: string;
  distress?: string;
  severity?: string;
  quantity?: number | string;
  mediaIds?: string[];
  notes?: string;
}

export interface CiModuleInspectionChoice {
  description: string;
  rating: string;
  points: number;
  emergency?: boolean;
}

export interface CiModuleInspection {
  name: string;
  rating?: string;
  mediaIds?: string[];
  comments?: string;
  choices: CiModuleInspectionChoice[];
}

export interface CiModuleInspectionUpdate {
  pageId?: string;
  fieldId: string;
  inspectionIndex: number;
  name: string;
  rating?: string;
  comments?: string;
  mediaIds?: string[];
}

export interface FormInstanceFieldItem {
  id?: string;
  type: string;
  text?: string;
  title?: string;
  description?: string;
  order: string[];
  required?: boolean;
  fileUpload?: boolean;
  unit?: string;
  enum?: string[];
  value?: string | string[] | number;
  assets: FormInstanceAssetItem[];
  assetSchemas: AssetSchemaType[];
  elements: FhwaModuleElements;
  // CI specific
  score?: CiModuleScore;
  inspections?: CiModuleInspection[];
  name?: string;
  // PCI specific
  pci?: PciModuleData;
}

export interface ConditionInspection {
  id: string;
  name: string;
  inspectionId: string;
  version: string;
  latest: boolean;
  score: CiModuleScore;
  inspections?: CiModuleInspection[];
}

export interface FormInstanceFields {
  [key: string]: FormInstanceFieldItem;
}

export interface FormInstancePageType {
  id: string;
  order: string[];
  name: string;
  fields: FormInstanceFields;
}

export interface FormInstanceMediaType {
  [key: string]: MediaItem[];
}

export interface FormInstanceTemplateType {
  id?: string;
  name?: string;
  schemaId?: string;
  createdDate?: number;
  createdBy?: string;
  updatedDate?: number;
  updatedBy?: string;
  workOrderId?: string;
  inventoryAssetId?: string;
  taskId?: string;
  formTemplateId?: string;
  media: FormInstanceMediaType;
  pages: FormInstancePageType[];
}

export interface FormModuleType {
  icon: React.ReactNode;
  color: string;
  title: string;
  pageName: string;
  description: string;
  getLongDescription: (schemas?: InventorySchemaItem[]) => string;
  type: string;
  previewImageSrc: any;
}

export enum FormModuleKeys {
  FHWA = 'fhwa',
  CI = 'ci',
  PCI = 'pci',
  PCI_SUMMARY = 'pciSummary',
}
