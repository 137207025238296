import React from 'react';
import { FontIcon } from 'material-ui';

import colors from '@atom/styles/colors';

import './tooltip.css';

interface Props {
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  position?: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';
  content: React.ReactNode;
}

const Tooltip = ({
  icon = 'help',
  iconSize = '1rem',
  iconColor = colors.neutral.silver,
  position = 'bottom-right',
  content,
}: Props) => {
  const getPositionStyle = () => {
    switch (position) {
      case 'bottom-right': {
        return { top: iconSize, left: iconSize };
      }
      case 'top-right': {
        return { bottom: iconSize, left: iconSize };
      }
      case 'top-left': {
        return { bottom: iconSize, right: iconSize };
      }
      case 'bottom-left': {
        return { top: iconSize, right: iconSize };
      }
      default: {
        return {};
      }
    }
  };

  return (
    <div styleName="tooltip-wrapper" style={{ width: iconSize }}>
      <FontIcon
        className="material-icons"
        style={{ fontSize: iconSize }}
        color={iconColor}
        children={icon}
      />
      <div styleName="tooltip-container" style={getPositionStyle()}>
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
