import React, { useContext, useState } from 'react';
import * as R from 'ramda';

import LocationsAndAssetsContext from '@atom/components/common/workOrderDetail/locationsAndAssetsSection/LocationsAndAssetsContext';
import RequiredFieldsConfirmModal from '@atom/components/common/workOrderDetail/requiredFieldsConfirmModal/RequiredFieldsConfirmModal';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AssetSettingsModal from './AssetSettingsModal';
import AssetTile from './AssetTile';

import './assetSection.css';

interface Props {
  setOpenTaskAsset: (openTaskAsset: boolean) => void;
  setOpenTaskMap: (openTaskMap: boolean) => void;
  handleAssetTileClick: (assetId: string) => void;
  previewView: boolean;
}

const AssetSection = ({
  setOpenTaskAsset,
  setOpenTaskMap,
  handleAssetTileClick,
  previewView,
}: Props) => {
  const {
    workOrderDetail,
    task,
    refetch,
    workValidations,
    dataCySuffix,
  } = useContext(LocationsAndAssetsContext);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [openAssetSettings, setOpenAssetSettings] = useState<boolean>(false);
  const [openFieldsConfirm, setOpenFieldsConfirm] = useState<boolean>(false);

  const onProceedComplete = async () => {
    await refetch();

    setOpenFieldsConfirm(false);
    setOpenTaskAsset(true);
  };

  const handleAddClick =
    task.isCompleted &&
    (task?.requireAtLeastOneMultiAsset ||
      !isNilOrEmpty(task?.requiredMultiAssetSchemaIds))
      ? () => setOpenFieldsConfirm(true)
      : () => setOpenTaskAsset(true);

  const icon = expanded ? 'expand_less' : 'expand_more';
  const isAddDisabled =
    workOrderDetail.isClosed || doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
  const showAddButton = expanded && !isAddDisabled;
  const showViewOnMapButton = expanded && !R.isEmpty(task?.assetIds);

  const isSectionEmpty = R.isEmpty(task?.assetIds);
  const totalCount = R.length(task?.assetIds);
  const hasRequiredAssets =
    task?.requireAtLeastOneMultiAsset ||
    !R.isEmpty(task?.requiredMultiAssetSchemaIds);

  const locationError = R.pathOr(
    false,
    ['taskValidations', task.id, 'missingRequiredAsset'],
    workValidations,
  );
  const requiredStyles = {
    color: locationError ? colors.brand.red : colors.neutral.gray,
  };
  const helpIconStyles = {
    fontSize: '1rem',
    color: locationError ? colors.brand.red : colors.neutral.gray,
  };
  const iconStyles = `help-icon-container ${!hasRequiredAssets ? 'only' : ''}`;
  const containerStyle = {
    justifyContent: previewView ? 'space-between' : 'flex-start',
  };
  const listStyle = {
    flexFlow: previewView ? 'column nowrap' : 'row wrap',
    marginRight: previewView ? '0.75rem' : 'none',
  };

  return (
    <>
      <div style={containerStyle} styleName="section-container">
        <div styleName="title-container">
          <Icon>category</Icon>
          <div styleName="section-title">Assets</div>
          <div style={requiredStyles} styleName="required-content">
            <span>{`(`}</span>
            {hasRequiredAssets && <span>Required</span>}
            <div
              styleName={iconStyles}
              onClick={() => setOpenAssetSettings(true)}
            >
              <Icon style={helpIconStyles}>help</Icon>
            </div>
            <span>{`)`}</span>
          </div>
          <div styleName="total-count">{totalCount}</div>
        </div>
        <div styleName="buttons-container">
          {showViewOnMapButton && (
            <IconButton
              edge="end"
              tooltip="View On Map"
              onClick={() => setOpenTaskMap(true)}
              data-cy={`assetSectionMap_${dataCySuffix}`}
            >
              <Icon>map</Icon>
            </IconButton>
          )}
          {showAddButton && (
            <IconButton
              edge="end"
              tooltip="Add Asset"
              onClick={handleAddClick}
              disabled={isAddDisabled}
              data-cy={`assetSectionAdd_${dataCySuffix}`}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => setExpanded(!expanded)} edge="end">
            <Icon>{icon}</Icon>
          </IconButton>
        </div>
      </div>
      {expanded && (
        <div style={listStyle} styleName="tile-list">
          {isSectionEmpty ? (
            <div styleName="empty-section-text">Add Assets</div>
          ) : (
            <>
              {task.assetIds.map((assetId, index) => (
                <AssetTile
                  key={`${assetId}${index}`}
                  assetId={assetId}
                  handleAssetTileClick={handleAssetTileClick}
                />
              ))}
            </>
          )}
        </div>
      )}
      <AssetSettingsModal
        open={openAssetSettings}
        onClose={() => setOpenAssetSettings(false)}
      />
      <RequiredFieldsConfirmModal
        open={openFieldsConfirm}
        onCancel={() => setOpenFieldsConfirm(false)}
        onComplete={onProceedComplete}
        task={task}
        workOrderId={workOrderDetail?.id}
      />
    </>
  );
};

export default AssetSection;
