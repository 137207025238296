import React from 'react';
import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

type CustomProps = {
  /**
   * optional styles to be applied to the root
   */
  style?: React.CSSProperties;
  /**
   * optional text for button tooltip
   */
  tooltip?: string;
  /**
   * optional props to pass to Tooltip
   */
  TooltipProps?: MUITooltipProps;
  /**
   * passthrough for component prop in MUI that is excluded from the
   * official MUI button props. Read more at:
   * https://material-ui.com/guides/typescript/#usage-of-component-prop
   */
  component?: any;
  /**
   * Default size padding was reduced by 8px which makes default IconButton
   * size of 40px with the upgrade to @mui/material. To get old default size,
   * of 48px, size is set to "large".
   */
  size?: 'small' | 'medium' | 'large';
};

export type IconButtonProps = CustomProps & MUIIconButtonProps;

const useStyles = makeStyles({
  // @ts-ignore
  root: ({ style }: IconButtonProps) => ({
    ...style,
  }),
});

export const IconButton = (props: IconButtonProps) => {
  const classes = useStyles(props);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style: _,
    size = 'large',
    tooltip = '',
    TooltipProps = {},
    ...data
  } = props;

  return (
    <MUITooltip title={tooltip} {...TooltipProps}>
      <MUIIconButton
        {...data}
        size={size}
        classes={{ root: classes.root, ...props.classes }}
      />
    </MUITooltip>
  );
};
