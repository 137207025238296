import * as R from 'ramda';

import lineStringUtilities from './lineStringUtilities';

/**
 *  @typedef {Object} LatLng - The LatLng Object
 *  @property {number} lat - The location latitude
 *  @property {number} lng - The location longitude
 */

/**
 *
 * @param {array} [coordinates] The coordinates array which has a latitude and longitude pair
 *
 *
 * This function returns the LatLng object back for users to consume.  It provides a consistent
 * mapping experience for all downstream consumers.
 *
 * This name mirrors that of the Google Maps API google.maps.LatLng Class
 *
 * The coordinates object has the following format:
 * [-34, 151]
 *
 * An example of the response object looks like the following:
 * {
 *   lat: -34,
 *   lng: 151
 * }
 *
 *  @returns {LatLng} LatLng - The LatLng Object
 */

export function getLatLng(coordinates) {
  if (!Array.isArray(coordinates)) {
    return null;
  }

  if (coordinates.length !== 2) {
    return null;
  }

  return {
    lat: coordinates[1],
    lng: coordinates[0],
  };
}

export function getCoordinates(location) {
  return [R.pathOr(0, ['lng'])(location), R.pathOr(0, ['lat'])(location)];
}

export function isPoint(location) {
  return location?.type === 'Point';
}

export function isLineString(location) {
  return location?.type === 'LineString';
}

export const getLineStringCenter = lineStringUtilities.getLineStringCenter;

const locationUtilities = {
  getLatLng,
  getCoordinates,
  isPoint,
  isLineString,
  getLineStringCenter,
};

export default locationUtilities;
