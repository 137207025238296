import * as R from 'ramda';

import { Task } from '@atom/types/task';
import {
  WorkOrderDetailType,
  WorkOrderType,
  WorkValidations,
} from '@atom/types/work';

import { isNilOrEmpty } from './validationUtilities';
import { isFieldInvalid } from './workOrderFieldUtilities';

interface ValidateWork {
  isTaskValid?: boolean;
  workValidations?: WorkValidations;
}

// Validates task against required multi asset rules
//  - requireAtLeastOneMultiAsset: true --> checks whether task has at least one asset
//  - requiredMultiAssetSchemaIds: [ids] --> checks whether task contains at least one asset of each specified schemaId
export const isTaskMultiAssetInvalid = (
  workOrderDetail: WorkOrderDetailType,
  task: Task,
): boolean => {
  const isMissingAtLeastOne =
    task?.requireAtLeastOneMultiAsset && isNilOrEmpty(task?.assetIds);

  const assets = R.pathOr([], ['assetIds'], task).reduce(
    (innerAcc, assetId) => {
      const asset = R.pathOr(null, ['multiAssets', assetId], workOrderDetail);
      return isNilOrEmpty(asset) ? innerAcc : [...innerAcc, asset];
    },
    [],
  );

  const uniqueSchemaIds = new Set(assets.map(asset => asset.schemaId));
  const uniqueAndRequireMatch = R.pathOr(
    [],
    ['requiredMultiAssetSchemaIds'],
    task,
  ).reduce((innerAcc, schemaId) => {
    return uniqueSchemaIds.has(schemaId) ? innerAcc : false;
  }, true);

  const isMissingSpecificAssets =
    !isNilOrEmpty(task?.requiredMultiAssetSchemaIds) && !uniqueAndRequireMatch;

  return isMissingAtLeastOne || isMissingSpecificAssets;
};

// Validates if all required items (custom fields / locations) are filled out on a work order.
//  - Custom fields are validated according to dataType specific rules in relation to the required boolean.
//  - For work created from work templates with type `TASK_ASSETS_AND_LOCATIONS` and requireAtLeastOneLocation: true, at least one location must be saved on each task.
//  - If a taskId is passed, validation will only be run on that specific task, otherwise it is run on all tasks on the given workOrder.
export const validateWork = (
  workOrderDetail: WorkOrderDetailType,
  taskId?: string,
): ValidateWork => {
  if (isNilOrEmpty(workOrderDetail.tasks)) {
    return {
      workValidations: { taskValidations: {} },
    };
  }

  const taskList = taskId
    ? workOrderDetail.tasks.filter(task => task.id === taskId)
    : workOrderDetail.tasks;

  // Creates dictionary of taskId to a set of required fieldIds missing values
  const invalidTaskFields = taskList.reduce((acc, task) => {
    if (isNilOrEmpty(task.fields)) {
      return acc;
    }

    const invalidFieldIds = task.fields.reduce((accumulator, field) => {
      return isFieldInvalid(field) ? [...accumulator, field.id] : accumulator;
    }, []);

    return {
      ...acc,
      ...(!isNilOrEmpty(invalidFieldIds) && {
        [task.id]: new Set(invalidFieldIds),
      }),
    };
  }, {});

  // Creates an array of taskId that do not include the required location
  const invalidTaskLocations = taskList.reduce((acc, task) => {
    if (workOrderDetail?.type !== WorkOrderType.TASK_ASSETS_AND_LOCATIONS) {
      return acc;
    }

    return task?.requireAtLeastOneLocation && isNilOrEmpty(task?.locations)
      ? [...acc, task?.id]
      : acc;
  }, []);

  // Creates an array of taskId that do not include the required assets
  const invalidTaskAssets = taskList.reduce((acc, task) => {
    if (workOrderDetail?.type !== WorkOrderType.TASK_ASSETS_AND_LOCATIONS) {
      return acc;
    }

    return isTaskMultiAssetInvalid(workOrderDetail, task)
      ? [...acc, task?.id]
      : acc;
  }, []);

  // Maps task field validations into WorkValidations definition
  const workValidationsTaskFields = R.keys(invalidTaskFields).reduce(
    (acc, invalidTaskId) => {
      return {
        ...acc,
        [invalidTaskId]: {
          invalidTaskFields: invalidTaskFields[invalidTaskId],
        },
      };
    },
    {},
  );

  // Maps task location validations into WorkValidations definition
  const taskLocationsValidations = invalidTaskLocations.reduce(
    (acc, invalidTaskId) => {
      return {
        ...acc,
        [invalidTaskId]: {
          ...acc[invalidTaskId],
          missingRequiredLocation: true,
        },
      };
    },
    workValidationsTaskFields,
  );

  // Maps task asset validations into WorkValidations definition
  const taskValidations = invalidTaskAssets.reduce((acc, invalidTaskId) => {
    return {
      ...acc,
      [invalidTaskId]: {
        ...acc[invalidTaskId],
        missingRequiredAsset: true,
      },
    };
  }, taskLocationsValidations);

  const isTaskValid = isNilOrEmpty(taskValidations[taskId]);

  return {
    workValidations: { taskValidations },
    ...(taskId && { isTaskValid }),
  };
};
