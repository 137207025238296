import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { GET_BUDGET_CHARTS } from '@atom/graph/budget';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  BudgetChart,
  BudgetChartsConnection,
  BudgetChartsConnectionInput,
} from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';

import {
  BarIcon,
  getChartColor,
  GroupKeys,
  LineIcon,
  renderLegendText,
  yAxisTickFormatter,
} from './expendituresChartUtils';
import { usePrepChartData } from './usePrepChartData';

const styles = {
  container: {
    height: '22rem',
  },
  tooltipContainer: {
    border: `2px solid ${colors.brand.blue}`,
    borderRadius: '0.25rem',
    background: colors.neutral.white,
    padding: '1rem',
  },
  tooltipTitle: {
    fontWeight: 500,
    fontSize: fonts.lg,
  },
  tooltipSubtitle: {
    fontWeight: 500,
    margin: '0.5rem 0',
    fontSize: fonts.md,
  },
  tooltipItem: { display: 'flex', alignItems: 'center', gap: '0.25rem' },
};

const BudgetDetailExpendituresChart = () => {
  const {
    budget,
    parentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
  } = useContext(BudgetDetailContext);

  const [budgetRaw, setBudgetRaw] = useState<BudgetChart[]>([]);
  const [chartData, setChartData] = useState([]);

  const {
    dataFields,
    actualFields,
    remainingFields,
    maxVal,
    data,
  } = usePrepChartData(budgetRaw);

  useEffect(() => setChartData(data), [data]);

  const [fetchCharts, { loading }] = useLazyQuery<
    { budgetCharts: BudgetChartsConnection },
    { input: BudgetChartsConnectionInput }
  >(GET_BUDGET_CHARTS, {
    fetchPolicy: 'network-only',
    onCompleted: dataResponse => {
      const budgetData: BudgetChart[] = R.pathOr(
        [],
        ['budgetCharts', 'budgetCharts'],
        dataResponse,
      );
      setBudgetRaw(budgetData);
    },
    onError: () => setBudgetRaw([]),
  });

  useEffect(() => {
    if (budget && parentBudgetUnit) {
      fetchCharts({
        variables: {
          input: {
            budgetId: budget?.id,
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            itemNames: budgetItemTemplateNames,
          },
        },
      });
    }
  }, [budget, parentBudgetUnit, categoryIds, budgetItemTemplateNames]);

  const getOpacities = useCallback(
    value =>
      dataFields.reduce((acc, field) => ({ ...acc, [field]: value }), [{}]),
    [dataFields],
  );

  const [opacity, setOpacity] = useState(getOpacities(1));

  const [hiddenData, setHiddenData] = useState<string[]>([]);

  const handleMouseEnter = elem => {
    const { dataKey } = elem;
    setOpacity({ ...getOpacities(0.25), [dataKey]: 1 });
  };

  const handleMouseLeave = () => setOpacity(getOpacities(1));

  const handleLegendClick = elem => {
    const { dataKey } = elem;
    const dataType = R.endsWith(GroupKeys.ACTUAL, dataKey)
      ? GroupKeys.ACTUAL
      : GroupKeys.REMAINING;
    setHiddenData(
      hiddenData.includes(dataType)
        ? R.without([dataType], hiddenData)
        : [...hiddenData, dataType],
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || isNilOrEmpty(payload)) {
      return null;
    }

    const fieldsMap = R.groupBy(R.prop('dataKey'), payload);
    const getField = (field, val) => R.pathOr('', [field, 0, val], fieldsMap);

    return (
      <div style={styles.tooltipContainer}>
        <div style={styles.tooltipTitle}>{label}</div>
        <div style={styles.tooltipSubtitle}>{GroupKeys.REMAINING}</div>
        {remainingFields.map(fieldName => (
          <div key={fieldName} style={styles.tooltipItem}>
            {LineIcon(getField(fieldName, 'stroke'))}
            {fieldName.split(GroupKeys.REMAINING)[0]}:
            <span>{numberToLocaleString(getField(fieldName, 'value'))}</span>
          </div>
        ))}
        <div style={styles.tooltipSubtitle}>{GroupKeys.ACTUAL}</div>
        {actualFields.map(fieldName => (
          <div key={fieldName} style={styles.tooltipItem}>
            {BarIcon(getField(fieldName, 'fill'))}
            {fieldName.split(GroupKeys.ACTUAL)[0]}:
            <span>{numberToLocaleString(getField(fieldName, 'value'))}</span>
          </div>
        ))}
      </div>
    );
  };

  // Display nothing when data is empty
  if (isNilOrEmpty(budgetRaw)) {
    return null;
  }

  return loading ? (
    <div style={styles.container}>
      <Progress />
    </div>
  ) : (
    <div style={styles.container}>
      <ResponsiveContainer>
        <ComposedChart
          data={chartData}
          margin={{ top: 20, right: 0, left: 0, bottom: 20 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis tickLine={false} axisLine={false} dataKey="name" />
          <YAxis
            tickLine={false}
            tickFormatter={tickVal => yAxisTickFormatter(tickVal, maxVal)}
          />
          <Tooltip formatter={numberToLocaleString} content={CustomTooltip} />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleLegendClick}
            formatter={renderLegendText}
          />
          <ReferenceLine y={0} stroke={colors.neutral.black} />
          {actualFields.map((fieldName, idx) => (
            <Bar
              dataKey={fieldName}
              key={fieldName}
              barSize={40}
              fill={getChartColor(idx)}
              fillOpacity={opacity[fieldName]}
              hide={hiddenData.includes(GroupKeys.ACTUAL)}
            />
          ))}
          {remainingFields.map((fieldName, idx) => (
            <Line
              type="monotone"
              dataKey={fieldName}
              key={fieldName}
              stroke={getChartColor(idx)}
              strokeWidth={2}
              strokeOpacity={opacity[fieldName]}
              hide={hiddenData.includes(GroupKeys.REMAINING)}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BudgetDetailExpendituresChart;
