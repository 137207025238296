import React, { useContext } from 'react';

import { Checkbox } from '@atom/mui';
import colors from '@atom/styles/colors';

import BudgetDetailContext from '../BudgetDetailContext';

import BudgetDetailCategoryFilter from './BudgetDetailCategoryFilter';
import BudgetDetailComparisonFilter from './BudgetDetailComparisonFilter';
import BudgetDetailItemFilter from './BudgetDetailItemFilter';

import '../budgetDetail.css';

const styles = {
  fixedFiltersContainer: {
    position: 'fixed',
    height: '75px',
    marginTop: '110px',
    padding: '1rem 1.5rem',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.neutral.white,
    zIndex: 100,
  },

  filterContainer: {
    padding: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },

  filterLabel: {
    color: colors.neutral.gray,
  },

  separator: {
    height: '2rem',
    width: '1px',
    backgroundColor: colors.neutral.ash,
    display: 'inline-block',
    padding: 0,
    marginLeft: '0.25rem',
  },
};

const BudgetDetailFilters = () => {
  const { showTracking, setShowTracking, showExpenditures } = useContext(
    BudgetDetailContext,
  );
  return (
    <div style={styles.filterContainer}>
      <div style={styles.filterLabel}>Filters:</div>
      <BudgetDetailCategoryFilter />
      <BudgetDetailItemFilter />
      <div style={styles.separator} />
      <div style={styles.filterLabel}>Compare:</div>
      <BudgetDetailComparisonFilter />
      {showExpenditures && (
        <>
          <div style={styles.separator} />
          <Checkbox
            onChange={() => setShowTracking(prev => !prev)}
            checked={showTracking}
            label="Show Tracking"
          />
        </>
      )}
    </div>
  );
};

export default BudgetDetailFilters;
