import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import Tooltip from '@atom/components/common/tooltip/Tooltip';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { WORK_TEMPLATE_TASK_UPDATE } from '@atom/graph/workTemplate';
import { Icon, IconButton, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  InheritedComponentType,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './workTemplateTaskDetail.css';

const styles = {
  icon: {
    padding: 0,
    fontSize: fonts.xl,
  },
  inputContainer: {
    marginTop: '4px',
  },
};

const TaskEstimatedCostRow = () => {
  const { workTemplate, task, refetch } = useContext(WorkTemplateContext);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [estimatedCost, setEstimatedCost] = useState<number>(
    task.estimatedCost,
  );
  const [isEstimatedCostOverridden, setIsEstimatedCostOverridden] = useState<
    boolean
  >(task.isEstimatedCostOverridden);

  const [taskTemplateUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  useEffect(() => {
    setIsEstimatedCostOverridden(task.isEstimatedCostOverridden);
  }, [task.isEstimatedCostOverridden]);

  useEffect(() => {
    setEstimatedCost(task.estimatedCost);
  }, [task.estimatedCost]);

  const handleToggle = async () => {
    const updatedValue = !isEstimatedCostOverridden;
    setIsEstimatedCostOverridden(updatedValue);

    if (!updatedValue) {
      await taskTemplateUpdate({
        variables: {
          input: {
            taskId: task.id,
            workTemplateId: workTemplate.id,
            isEstimatedCostOverridden: false,
          },
        },
      });

      refetch();
    }

    if (updatedValue) {
      setEditMode(true);
    }
  };

  const cancel = () => {
    const updatedValue = !isEstimatedCostOverridden;

    setIsEstimatedCostOverridden(updatedValue);
    setEditMode(false);
  };

  const confirm = async () => {
    await taskTemplateUpdate({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          isEstimatedCostOverridden: true,
          estimatedCost,
        },
      },
    });

    refetch();

    setEditMode(false);
  };

  const icon = isEstimatedCostOverridden ? 'refresh' : 'edit';

  const tooltipContent = (
    <div styleName="tooltip-content">
      <strong>How is Estimated Cost calculated? </strong>
      <div>Two options:</div>
      <div>1. Manual input</div>
      <div>2. Group Budget x Work Time Per Person x Number of People</div>
    </div>
  );

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const isInherited = isComponentInherited(
    InheritedComponentType.TASK_ESTIMATED_COST,
    task?.inheritedComponents || [],
  );

  const actionButton = isInherited ? (
    <InheritanceLockIcon
      tooltip="Inherited Estimated Cost cannot be removed or edited."
      placement="bottom-start"
    />
  ) : (
    <IconButton size="small" onClick={handleToggle} disabled={isDisabled}>
      <Icon style={styles.icon}>{icon}</Icon>
    </IconButton>
  );

  return (
    <div styleName="cost-content-left">
      <div styleName="label-container">
        <div styleName="label">Estimated Cost</div>
        <Tooltip content={tooltipContent} position="bottom-left" />
      </div>
      {editMode ? (
        <div styleName="task-cost-textfield-container">
          <TextField
            id="name"
            autoFocus
            type="number"
            value={estimatedCost}
            onChange={event => setEstimatedCost(Number(event.target.value))}
            disabled={isDisabled}
            style={styles.inputContainer}
          />
          <div styleName="button-container">
            <div styleName="button">
              <Icon color={colors.brand.blue} onClick={cancel}>
                close
              </Icon>
            </div>
            <div styleName="button">
              <Icon color={colors.brand.blue} onClick={confirm}>
                check
              </Icon>
            </div>
          </div>
        </div>
      ) : (
        <div styleName="estimated-cost-container">
          <div styleName="estimated-cost-value">
            {numberToLocaleString(task.estimatedCost)}
          </div>
          {actionButton}
        </div>
      )}
    </div>
  );
};

export default TaskEstimatedCostRow;
