import * as R from 'ramda';

import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

// Checks all values (edited or not) of attributes
// and returns and array attributeIds that are missing values
export const getErrorAttributeIds = (
  form: any,
  attributeGroup: any,
): string[] => {
  if (isNilOrEmpty(attributeGroup)) {
    return [];
  }

  const requiredValueMap = attributeGroup.attributes.reduce(
    (acc, attribute) => {
      const value = R.has(attribute.id, form)
        ? form[attribute.id]
        : attribute.value;

      return attribute.isRequired
        ? {
            ...acc,
            [attribute.id]: value,
          }
        : acc;
    },
    {},
  );

  return R.keys(requiredValueMap).reduce((acc, attributeId) => {
    return isNilOrEmpty(requiredValueMap[attributeId])
      ? [...acc, attributeId]
      : acc;
  }, []);
};
